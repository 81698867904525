/*
 * @Author: yanghy5
 * @Email: yanghy5@guahao.com
 * @Date: 2019-10-28 15:52:54
 * @Last Modified by: yanghy5
 * @Last Modified time: 2019-11-11 10:10:06
 * @Description: 招标模式管理
 * @Route: Route
 */

const modeList = () => import(/* webpackChunkName: "modeList" */ '@/pages/tender/bidding-mode/list');
const AddOrEdit = () => import(/* webpackChunkName: "AddOrEdit" */ '@/pages/tender/bidding-mode/add-edit');
const Detail = () => import(/* webpackChunkName: "Detail" */ '@/pages/tender/bidding-mode/detail');
export default [
    {
        path: 'bidding-mode/list',
        meta: {
            title: '招标模式管理'
        },
        component: modeList
    },
    {
        path: 'bidding-mode/list/add',
        component: AddOrEdit,
        meta: {
            title: '新增招标模式'
        }
    },
    {
        path: 'bidding-mode/list/edit',
        component: AddOrEdit,
        meta: {
            title: '编辑招标模式'
        }
    },
    {
        path: 'bidding-mode/list/detail',
        component: Detail,
        meta: {
            title: '查看招标模式'
        }
    }
];