import View from '@/components/view';
import Agreement from './agreement';
import PublicInfo from './public-info';
import PolicyData from './policy-data-manage';

const feedbackManagement = () => import(/* webpackChunkName: "feedback-management" */ '@/pages/bdc/public-service/feedback-management/feedback-management.vue');


export default [
    {
        path: 'public-service',
        component: View,
        meta: {
            title: '基本业务服务'
        },
        children: [
            ...PolicyData,
            ...PublicInfo,
            ...Agreement,

            {
                path: 'feedback-management',
                component: feedbackManagement,
                meta: {
                    title: '下发数据反馈管理'
                }
            }
        ]
    }
];
