/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2020-12-14 17:01:02
 * @Last Modified by: yanshuai
 * @Last Modified time: 2020-12-17 18:23:34
 * @Description:  药品招采统计分析
 */

const netDirectoryQuery = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/drug-tatistical-analysis/net-directory-query');
const drugHospitalDirectory = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/drug-tatistical-analysis/drug-hospital-directory');
const drugTradingProfile = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/drug-tatistical-analysis/drug-trading-profile');
const varietyTradingAnalysis = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/drug-tatistical-analysis/variety-trading-analysis');
const recordPurchaseInquiry = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/drug-tatistical-analysis/record-purchase-inquiry');
const tradAnalysisProduct = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/drug-tatistical-analysis/trad-analysis-product');
const purchaseTop = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/drug-tatistical-analysis/purchase-top');

const banlanceList = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/drug-tatistical-analysis/balance/banlance-list');

export default [
    {
        path: 'full/drug/net-directory-query',
        component: netDirectoryQuery,
        meta: {
            title: '药品挂网目录查询'
        }
    },
    {
        path: 'full/drug/drug-hospital-directory',
        component: drugHospitalDirectory,
        meta: {
            title: '药品院内目录查询'
        }
    },
    {
        path: 'full/drug/drug-trading-profile',
        component: drugTradingProfile,
        meta: {
            title: '药品交易概况分析'
        }
    },
    {
        path: 'full/drug/variety-trading-analysis',
        component: varietyTradingAnalysis,
        meta: {
            title: '品种交易分析'
        }
    },
    {
        path: 'full/drug/record-purchase-inquiry',
        component: recordPurchaseInquiry,
        meta: {
            title: '药品备案采购查询'
        }
    },
    {
        path: 'full/drug/trad-analysis-product',
        component: tradAnalysisProduct,
        meta: {
            title: '生产企业药品交易分析'
        }
    },
    {
        path: 'full/drug/purchase-top',
        component: purchaseTop,
        meta: {
            title: '品种交易分析'
        }
    },
    {
        path: 'fulll/drug/balance/balance-list',
        component: banlanceList,
        meta: {
            title: '医疗机构结余统计'
        }
    }
    
];