/*
 * @Author: panzw2
 * @Email: panzw2@guahao.com
 * @Date: 2020-12-14 19:01:37
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-12-14 11:17:12
 * @Description: 综合监管/机构信息查询
 */

const distribution = () => import(/* webpackChunkName: "drug-analyze" */ '@/pages/mgr/project-analysis/agency-information/distribution');
const medical = () => import(/* webpackChunkName: "drug-ranking" */ '@/pages/mgr/project-analysis/agency-information/medical');
const production = () => import(/* webpackChunkName: "drug-ranking" */ '@/pages/mgr/project-analysis/agency-information/production');
export default [
    {
        path: 'agency-information/distribution',
        component: distribution,
        meta: {
            title: '配送企业查询'
        }
    },
    {
        path: 'agency-information/medical',
        component: medical,
        meta: {
            title: '医疗机构查询'
        }
    },
    {
        path: 'agency-information/production',
        component: production,
        meta: {
            title: '生产企业查询'
        }
    }
];