/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-11-21 14:44:31
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-21 14:46:30
 * @Description: 综合监管/履约评分设置
 */
const List = () => import(/* webpackChunkName: "performance-score-set-list" */ '@/pages/mgr/project-analysis/performance-score-set/list');

export default [
    {
        path: 'performance-score-set/list',
        component: List,
        meta: {
            title: '履约评分设置'
        }
    }
];