import View from '@/components/view';

// 目录管理-药品-医疗机构
const hangingNetCatalogSearch = () => import(/* webpackChunkName: "hangingNetCatalogSearch" */ '@/pages/trade/catalog-management/drug/hanging-net-catalog-search-list');
const catalogSearchListRead = () => import(/* webpackChunkName: "catalogSearchListRead" */ '@/pages/trade/catalog-management/drug/catalog-search-list-read');
const hospitalCatalogManageList = () => import(/* webpackChunkName: "hospitalCatalogManageList" */ '@/pages/trade/catalog-management/drug/hospital-catalog-manage-list');
const secondArgaininManagement = () => import(/* webpackChunkName: "secondArgaininManagement" */ '@/pages/trade/catalog-management/drug/second-argainin-management');
const secondArgaininManagementAdd = () => import(/* webpackChunkName: "secondArgaininManagement" */ '@/pages/trade/catalog-management/drug/second-argainin-management-add');

const routes = [
    {
        path: 'catalog-management/drug',
        component: View,
        meta: {
            title: '目录管理'
        },
        children: [
            {
                path: 'hanging-net-catalog-search/list',
                component: hangingNetCatalogSearch,
                meta: {
                    title: '挂网目录查询'
                }
            },
            {
                path: 'hanging-net-catalog-search/list/read',
                component: catalogSearchListRead,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'hospital-catalog-manage-list',
                component: hospitalCatalogManageList,
                meta: {
                    title: '院内目录管理'
                }
            },
            {
                path: 'second-argainin-management/list',
                component: secondArgaininManagement,
                meta: {
                    title: '二次议价管理'
                }
            },
            {
                path: 'second-argainin-management/list/add',
                component: secondArgaininManagementAdd,
                meta: {
                    title: '新增',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;