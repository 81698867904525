
/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 发布结果查询/竞价发布结果
 */

const RegBiddingPublish = () => import(/* webpackChunkName: "rd-list" */ '@/pages/tender/publish-result/bidding/regulator-org/list');
const ProBiddingPublish = () => import(/* webpackChunkName: "rd-list" */ '@/pages/tender/publish-result/bidding/product-org/list');
export default [
    {
        path: 'bidding/regulator-org/list',
        component: RegBiddingPublish,
        meta: {
            title: '竞价发布管理',
            display: 'none'
        }
    },
    {
        path: 'bidding/product-org/list',
        component: ProBiddingPublish,
        meta: {
            title: '竞价发布结果',
            display: 'none'
        }
    }
];
