const baseSize = 16;
const setRem = () => {
    const { clientHeight } = document.documentElement;
    const scale = clientHeight / 756;
    document.documentElement.style.fontSize = `${(baseSize * Math.min(scale, 2))}px`;
    document.documentElement.style.overflow = 'hidden';
};

if (window.location.pathname.indexOf('/mgr/visual/home') !== -1) {
    setRem();
}

window.onresize = () => {
    if (window.location.pathname.indexOf('/mgr/visual/home') !== -1) {
        setRem();
    }
};