/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-12-09
 * @Last Modified by: wangxiaoyu
 * @Last Modified time: 2019-12-09
 * @Description: 项目内药品-监管端页面路由
 */

const DrugList = () => import(/* webpackChunkName: "aduit-list" */ '@/pages/tender/signup-manage/enterprise/drug/manager/audit-list');
const DrugReviewingPage = () => import(/* webpackChunkName: "reviewing-page" */ '@/pages/tender/signup-manage/enterprise/drug/manager/reviewing-page');
const DrugReviewedPage = () => import(/* webpackChunkName: "reviewed-page" */ '@/pages/tender/signup-manage/enterprise/drug/manager/reviewed-page');
const DrugComparePage = () => import(/* webpackChunkName: "compare-page" */ '@/pages/tender/signup-manage/enterprise/drug/manager/compare-page');
const DrugHistoryPage = () => import(/* webpackChunkName: "history-page" */ '@/pages/tender/signup-manage/enterprise/drug/manager/history-page');

export default [
    {
        path: 'enterprise/drug/manager/aduit-list',
        component: DrugList,
        meta: {
            title: '企业报名审核'
        }
    },
    {
        path: 'enterprise/drug/manager/aduit-list/review',
        component: DrugReviewingPage,
        meta: {
            title: '审核',
            display: 'none'
        }
    },
    {
        path: 'enterprise/drug/manager/aduit-list/details',
        component: DrugReviewedPage,
        meta: {
            title: '审核详情',
            display: 'none'
        }
    },
    {
        path: 'enterprise/drug/manager/aduit-list/details/compare',
        component: DrugComparePage,
        meta: {
            title: '比对详情',
            display: 'none'
        }
    },
    {
        path: 'enterprise/drug/manager/aduit-list/details/history',
        component: DrugHistoryPage,
        meta: {
            title: '查看历史',
            display: 'none'
        }
    }
];
