/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-02-20 14:28:14
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-08-21 15:36:42
 * @Description: 各区县采购进度与结算分析
 * @Route: Route
 */
const List = () => import(/* webpackChunkName: "analysis-mgr-list" */ '@/pages/mgr/procurement-project/purchase-analysis/country-area-analysis/list');
const Detail = () => import(/* webpackChunkName: "analysis-mgr-detail" */ '@/pages/mgr/procurement-project/purchase-analysis/country-area-analysis/detail');

export default [
    {
        path: 'country-area-analysis/list',
        component: List,
        meta: {
            title: '各区县采购进度与结算分析'
        }
    },
    {
        path: 'country-area-analysis/list/detail',
        component: Detail,
        meta: {
            title: '单个县区采购分析'
        }
    }
];