/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-12-09
 * @Last Modified by: wangxiaoyu
 * @Last Modified time: 2019-12-09
 * @Description: 项目内耗材-监管端页面路由
 */

const AuditList = () => import(/* webpackChunkName: "aduit-list" */ '@/pages/tender/signup-manage/enterprise/consumable/manager/audit-list');
const ReviewingPage = () => import(/* webpackChunkName: "reviewing-page" */ '@/pages/tender/signup-manage/enterprise/consumable/manager/reviewing-page');
const ReviewedPage = () => import(/* webpackChunkName: "reviewed-page" */ '@/pages/tender/signup-manage/enterprise/consumable/manager/reviewed-page');
const ComparePage = () => import(/* webpackChunkName: "compare-page" */ '@/pages/tender/signup-manage/enterprise/consumable/manager/compare-page');
const HistoryPage = () => import(/* webpackChunkName: "history-page" */ '@/pages/tender/signup-manage/enterprise/consumable/manager/history-page');


export default [
    {
        path: 'enterprise/consumable/manager/audit-list',
        component: AuditList,
        meta: {
            title: '企业报名列表'
        }
    },
    {
        path: 'enterprise/consumable/manager/audit-list/reviewing-page',
        component: ReviewingPage,
        meta: {
            title: '审核',
            display: 'none'
        }
    },
    {
        path: 'enterprise/consumable/manager/audit-list/reviewed-page',
        component: ReviewedPage,
        meta: {
            title: '查看详情',
            display: 'none'
        }
    },
    {
        path: 'enterprise/consumable/manager/compare-page',
        component: ComparePage,
        meta: {
            title: '详情对比',
            display: 'none'
        }
    },
    {
        path: 'enterprise/consumable/manager/history-page',
        component: HistoryPage,
        meta: {
            title: '查看历史',
            display: 'none'
        }
    }
];
