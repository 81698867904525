/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-07-26 11:18:42
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-10-09 10:49:03
 * @Description: 违规处罚监管
 */
import View from '@/components/view';
import Handle from './handle';
import Query from './query';
import ProductBlacklist from './product-blacklist-manage';
import ProductBlackLog from './product-blacklist-log';
import CustomizedSolutions from './customized-solutions';

export default [
    {
        path: 'illegal-punish',
        component: View,
        meta: {
            title: '违规处罚'
        }, 
        children: [
            ...Handle,
            ...Query,
            ...ProductBlacklist,
            ...ProductBlackLog,
            ...CustomizedSolutions
        ]
    }
];