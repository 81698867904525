import Fetch from '@/utils/fetch';

// 中选产品列表
export const queryChosenDrug = params => (
    Fetch({
        url: '/item/drug/selectChoPage',
        params,
        method: 'post'
    })
);

// 中选产品管理-获取非中选产品信息
export const queryNoChosenDrug = params => (
    Fetch({
        url: '/web/mgr/itemDrug/queryPage',
        params,
        method: 'post'

    })
);

// 中选产品管理-修改(非)中选产品信息
export const submitDrug = params => (
    Fetch({
        url: '/web/mgr/itemDrug/saveDrug',
        params,
        method: 'post'
    })
);

// 结构约定量配置-导入
export const uploadOrgExcel = params => (
    Fetch({
        url: '/demo/post',
        params,
        method: 'post'
    })
);

// 结构约定量配置-编辑前查询
export const getOrgAppoint = params => (
    Fetch({
        url: '/web/mgr/medinsItem/queryMedinsRpupCnt',
        params,
        method: 'post'
    })
);

// 结构约定量配置-编辑保存
export const submitOrgAppoint = params => (
    Fetch({
        url: '/web/mgr/medinsItem/saveMedinsItem',
        params,
        method: 'post'
    })
);


// 设置中选品种-新增带量采购项目
export const addPurcItem = '/web/mgr/purcitem/addPurcItem';

// 设置中选品种-编辑带量采购项目
export const editPurcItem = '/web/mgr/purcitem/editPurcItem';

// 设置中选品种-新增中选产品
export const saveDrug = params => (
    Fetch({
        url: '/web/mgr/itemDrug/saveDrug',
        params,
        method: 'post'
    })
);

/**
 *  带量采购项目
 */
export const getPurcitem = params => Fetch({
    url: '/web/mgr/purcitem/queryPage',
    method: 'post',
    params
});

// 耗材中选品种模板下载
export const importChoDrugModel = params => Fetch({
    url: '/web/mgr/purcitemmcs/importModel',
    method: 'post',
    params
});
// 约定量导入模板下载
export const importModel = params => (
    Fetch({
        url: '/web/mgr/medinsItem/importModel', 
        method: 'post',
        params
    })
);

// 约定量导入关联产品模板下载
export const expListProdRltsModel = params => (
    Fetch({
        url: '/web/mgr/purclist/expListProdRltsModel', 
        method: 'post',
        params
    })
);

// 约定量导入目录模板下载
export const expPurcListModel = params => (
    Fetch({
        url: '/web/mgr/purclist/expPurcListModel', 
        method: 'post',
        params
    })
);

// 约定量导入模板下载
export const importModelpro = params => (
    Fetch({
        url: '/web/mgr/medinsItem/importModel', 
        method: 'post',
        params
    })
);
//  校验是否有关联产品
export const checkRlts = params => (
    Fetch({
        url: '/web/mgr/purclist/checkRlts',
        method: 'post',
        params
    })
);

//  清空关联产品
export const deleteListProd = params => (
    Fetch({
        url: '/web/mgr/purclist/deleteListProd',
        method: 'post',
        params
    })
);