// 备案采购-药品-管理机构
const secondNegotiation = () => import(/* webpackChunkName: "recordUrchase" */ '@/pages/trade/second-negotiation/drug/secondary-bargaining-manage-list');

const routes = [
    {
        path: 'second-negotiation/drug/secondary-bargaining-manage-list',
        component: secondNegotiation,
        meta: {
            title: '二次议价信息管理'
        }
    }
];

export default routes;