/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-11-11 14:00:56
 * @Last Modified by: maozg
 * @Last Modified time: 2019-11-26 14:03:37
 * @Description: 收退货管理模块
 */

import View from '@/components/view';

const ReceiveList = () => import('@/pages/trade/goods-manage/consumable/receive-list');
const ReceiveDetails = () => import('@/pages/trade/goods-manage/consumable/receive-details');
const ReceviceConfirm = () => import('@/pages/trade/goods-manage/consumable/recevice-confirm');
const ReturnList = () => import('@/pages/trade/goods-manage/consumable/return-list');
const ReturnApply = () => import('@/pages/trade/goods-manage/consumable/return-apply');
const ReturnDetail = () => import('@/pages/trade/goods-manage/consumable/return-detail');
const StoreManage = () => import('@/pages/trade/goods-manage/consumable/store-manage');


export default [
    {
        path: 'consumable/goods-manage',
        component: View,
        meta: {
            title: '收退货管理'
        },
        children: [
            {
                path: 'receive-list',
                component: ReceiveList,
                meta: {
                    title: '收货管理'
                }
            },
            {
                path: 'receive-list/details',
                component: ReceiveDetails,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'receive-list/confirm',
                component: ReceviceConfirm,
                meta: {
                    title: '确认收货',
                    display: 'none'
                }
            },

            {
                path: 'return-list',
                component: ReturnList,
                meta: {
                    title: '退货管理'
                }
            },
            {
                path: 'return-list/apply',
                component: ReturnApply,
                meta: {
                    title: '申请退货',
                    display: 'none'
                }
            },
            {
                path: 'return-list/apply/detail',
                component: ReturnDetail,
                meta: {
                    title: '申请退货详情',
                    display: 'none'
                }
            },
            {
                path: 'store-manage',
                component: StoreManage,
                meta: {
                    title: '库房管理'
                }
            }
        ]
    }
];
