/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-11-04 18:13:25
 * @Last Modified by: yanshuai
 * @Last Modified time: 2021-02-02 17:38:01
 * @Description: 耗材招采统计分析总路由
 */
import View from '@/components/view';

const PurchaseDetail = () => import(/* webpackChunkName: "cons-purchase-detail" */ '@/pages/mgr/tender-stat-cons/purchase-detail');
const PurchaseConfluence = () => import(/* webpackChunkName: "cons-purchase-confluence" */ '@/pages/mgr/tender-stat-cons/purchase-confluence');
const TradAnalysisMedical = () => import(/* webpackChunkName: "cons-trad-analysis-medical" */ '@/pages/mgr/tender-stat-cons/trad-analysis-medical');
const TradAnalysisDelivery = () => import(/* webpackChunkName: "cons-trad-analysis-delivery" */ '@/pages/mgr/tender-stat-cons/trad-analysis-delivery');
const TradAnalysisProduct = () => import(/* webpackChunkName: "cons-trad-analysis-product" */ '@/pages/mgr/tender-stat-cons/trad-analysis-product');
const BillMessage = () => import(/* webpackChunkName: "cons-bill-message" */ '@/pages/mgr/tender-stat-cons/bill-message');
const SettmDetail = () => import(/* webpackChunkName: "cons-settm-detail" */ '@/pages/mgr/tender-stat-cons/settm-detail');
const ReceiveConflSupply = () => import(/* webpackChunkName: "cons-receive-confl-supply" */ '@/pages/mgr/tender-stat-cons/receive-confl-supply');
const PayConflMedical = () => import(/* webpackChunkName: "cons-pay-confl-medical" */ '@/pages/mgr/tender-stat-cons/pay-confl-medical');
const SettmStatSupply = () => import(/* webpackChunkName: "cons-settm-stat-supply" */ '@/pages/mgr/tender-stat-cons/settm-stat-supply');
const SettmStatMedical = () => import(/* webpackChunkName: "cons-settm-stat-medical" */ '@/pages/mgr/tender-stat-cons/settm-stat-medical');
// 耗材票据信息查询
const consumableBillInfo = () => import(/* webpackChunkName: "consumable-bill-info" */ '@/pages/mgr/tender-stat-cons/consumable-bill-info');
// 耗材结算明细
const consumablesSettDetails = () => import(/* webpackChunkName: "consumables-sett-details" */ '@/pages/mgr/tender-stat-cons/consumables-sett-details');
// 配送企业耗材结算统计
const deliverySettStatistics = () => import(/* webpackChunkName: "list" */ '@/pages/mgr/tender-stat-cons/delivery-sett-statistics/list');
// 医疗机构耗材结算统计
const medicalSettStatistics = () => import(/* webpackChunkName: "list" */ '@/pages/mgr/tender-stat-cons/medical-sett-statistics/list');
// 配送企业耗材应收汇总
const deliveryReceiveCollect = () => import(/* webpackChunkName: "list" */ '@/pages/mgr/tender-stat-cons/delivery-receive-collect/list');
// 医疗机构耗材应付汇总
const medicalReceiveCollect = () => import(/* webpackChunkName: "list" */ '@/pages/mgr/tender-stat-cons/medical-receive-collect/list');


export default [ 
    {
        path: 'tender-stat-cons',
        component: View,
        meta: {
            title: '耗材招采统计分析'
        },
        children: [
            {
                path: 'purchase-detail',
                component: PurchaseDetail,
                meta: {
                    title: '耗材采购明细'
                }
            },
            {
                path: 'purchase-confluence',
                component: PurchaseConfluence,
                meta: {
                    title: '耗材采购汇总'
                }
            },
            {
                path: 'trad-analysis-medical',
                component: TradAnalysisMedical,
                meta: {
                    title: '医疗机构耗材交易分析'
                }
            },
            {
                path: 'trad-analysis-delivery',
                component: TradAnalysisDelivery,
                meta: {
                    title: '配送企业耗材交易分析'
                }
            },
            {
                path: 'trad-analysis-product',
                component: TradAnalysisProduct,
                meta: {
                    title: '生产企业耗材交易分析'
                }
            },
            {
                path: 'bill-message',
                component: BillMessage,
                meta: {
                    title: '耗材票据信息查询'
                }
            },
            {
                path: 'settm-detail',
                component: SettmDetail,
                meta: {
                    title: '耗材结算明细'
                }
            },
            {
                path: 'receive-confl-supply',
                component: ReceiveConflSupply,
                meta: {
                    title: '供应企业耗材应收汇总'
                }
            },
            {
                path: 'pay-confl-medical',
                component: PayConflMedical,
                meta: {
                    title: '医疗结构耗材应付汇总'
                }
            },
            {
                path: 'settm-stat-supply',
                component: SettmStatSupply,
                meta: {
                    title: '供应企业耗材结算统计'
                }
            },
            {
                path: 'settm-stat-medical',
                component: SettmStatMedical,
                meta: {
                    title: '医疗机构耗材结算统计'
                }
            },
            {
                path: 'consumable-bill-info',
                component: consumableBillInfo,
                meta: {
                    title: '耗材票据信息查询'
                }
            },
            {
                path: 'consumables-sett-details',
                component: consumablesSettDetails,
                meta: {
                    title: '耗材结算明细'
                }
            },
            {
                path: 'delivery-sett-statistics',
                component: deliverySettStatistics,
                meta: {
                    title: '配送企业耗材结算统计'
                }
            },
            {
                path: 'medical-sett-statistics',
                component: medicalSettStatistics,
                meta: {
                    title: '医疗机构耗材结算统计'
                }
            },
            {
                path: 'delivery-receive-collect',
                component: deliveryReceiveCollect,
                meta: {
                    title: '配送企业耗材应收汇总'
                }
            },
            {
                path: 'medical-receive-collect',
                component: medicalReceiveCollect,
                meta: {
                    title: '医疗机构耗材应付汇总'
                }
            }
        ]
    }
];