/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 分组管理/目录分组管理
 */

const List = () => import(/* webpackChunkName: "list" */ '@/pages/tender/group-manage/product/list');
const Log = () => import(/* webpackChunkName: "log" */ '@/pages/tender/group-manage/product/log');

export default [
    {
        path: 'product/list',
        component: List,
        meta: {
            title: '产品分组调整'
        }
    },
    {
        path: 'product/list/log',
        component: Log,
        meta: {
            title: '调整日志'
        }
    }
];
