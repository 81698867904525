/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-08-22 15:33:47
 * @Last Modified by: maozg
 * @Last Modified time: 2019-09-05 14:20:34
 * @Description: 业务监管/监测预警/价格异常预警
 */

const List = () => import(/* webpackChunkName: "price-abnormal-list" */ '@/pages/mgr/business/monitor-warning/price-abnormal/list');
const ListProvince = () => import(/* webpackChunkName: "price-abnormal-list" */ '@/pages/mgr/business/monitor-warning/price-abnormal/list-province');
const Detail = () => import(/* webpackChunkName: "price-abnormal-list" */ '@/pages/mgr/business/monitor-warning/price-abnormal/detail');

export default [
    {
        path: 'price-abnormal/list',
        component: List,
        meta: {
            title: '价格异常预警'
        }
    },
    {
        path: 'price-abnormal/list-province',
        component: ListProvince,
        meta: {
            title: '各省价格异常预警',
            display: 'none'
        }
    },
    {
        path: 'price-abnormal/detail',
        component: Detail,
        meta: {
            title: '全国药品均价异常涨跌幅明细',
            display: 'none'
        }
    }
];
