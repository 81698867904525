/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-08-22 15:33:47
 * @Last Modified by: maozg
 * @Last Modified time: 2019-09-05 14:08:48
 * @Description: 业务监管/监测预警/配送率不达标预警
 */

const List = () => import(/* webpackChunkName: "delivery-unstandard-list" */ '@/pages/mgr/business/monitor-warning/delivery-unstandard/list');
const ListProvince = () => import(/* webpackChunkName: "delivery-unstandard-list" */ '@/pages/mgr/business/monitor-warning/delivery-unstandard/list-province');
const Detail = () => import(/* webpackChunkName: "delivery-unstandard-list" */ '@/pages/mgr/business/monitor-warning/delivery-unstandard/detail');

export default [
    {
        path: 'delivery-unstandard/list',
        component: List,
        meta: {
            title: '配送率不达标预警'
        }
    },
    {
        path: 'delivery-unstandard/list-province',
        component: ListProvince,
        meta: {
            title: '各省配送企业配送率不达标预警',
            display: 'none'
        }
    },
    {
        path: 'delivery-unstandard/detail',
        component: Detail,
        meta: {
            title: '配送企业配送率趋势详情',
            display: 'none'
        }
    }
];
