/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-08-22 15:33:47
 * @Last Modified by: housy
 * @Last Modified time: 2019-08-22 17:26:07
 * @Description: 业务监管/监测预警
 */

import View from '@/components/view';
import PurchaseAbnormal from './purchase-abnormal';
import PriceAbnormal from './price-abnormal';
import IntelliValidity from './intelli-validity';
import DeliveryUnstandard from './delivery-unstandard';
import TakeDeliveryUnstandard from './take-delivery-unstandard';
import SettlementMoneybackUnstandard from './settlement-moneyback-unstandard';

export default [
    {
        path: 'monitor-warning',
        component: View,
        meta: {
            title: '监测预警'
        },
        children: [
            ...PurchaseAbnormal,
            ...PriceAbnormal,
            ...IntelliValidity,
            ...DeliveryUnstandard,
            ...TakeDeliveryUnstandard,
            ...SettlementMoneybackUnstandard
        ]
    }
];