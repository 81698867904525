/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-12-09
 * @Last Modified by: wangxiaoyu
 * @Last Modified time: 2019-12-09
 * @Description: 项目内企业报名路由汇总
 */
import DrugSign from './drug/sign';
import DrugManager from './drug/manager';
import ConsumableSign from './consumable/sign';
import ConsumableManager from './consumable/manager';

export default [
    ...DrugSign,
    ...DrugManager,
    ...ConsumableSign,
    ...ConsumableManager
];

//
// // 企业报名页面
// const SignUp = () => import(/* webpackChunkName: "sign-up" */ '@/pages/tender/signup-manage/enterprise/sign-up');
// // 管理机构审核页面
// const Review = () => import(/* webpackChunkName: "Review" */ '@/pages/tender/signup-manage/enterprise/review');
// // 管理机构审核通过查看详情页面
// const View = () => import(/* webpackChunkName: "View" */ '@/pages/tender/signup-manage/enterprise/view');
//
// // 12月7号NEW
//
// // 药品
// //    管理端
// const DrugList = () => import(/* webpackChunkName: "aduit-list" */ '@/pages/tender/signup-manage/enterprise/drug/manager/audit-list');
// const DrugReviewingPage = () => import(/* webpackChunkName: "reviewing-page" */ '@/pages/tender/signup-manage/enterprise/drug/manager/reviewing-page');
// const DrugReviewedPage = () => import(/* webpackChunkName: "reviewed-page" */ '@/pages/tender/signup-manage/enterprise/drug/manager/reviewed-page');
// const DrugComparePage = () => import(/* webpackChunkName: "compare-page" */ '@/pages/tender/signup-manage/enterprise/drug/manager/compare-page');
// const DrugHistoryPage = () => import(/* webpackChunkName: "history-page" */ '@/pages/tender/signup-manage/enterprise/drug/manager/history-page');
//
// //    报名企业端
// const Business = () => import(/* webpackChunkName: "business" */ '@/pages/tender/signup-manage/enterprise/drug/sign-up/business');
//
// // 耗材
// //    管理端
// const ConsumableList = () => import(/* webpackChunkName: "aduit-list" */ '@/pages/tender/signup-manage/enterprise/consumable/audit-list');
// const ConsumableReviewingPage = () => import(/* webpackChunkName: "reviewing-page" */ '@/pages/tender/signup-manage/enterprise/consumable/reviewing-page');
// // const ConsumableReviewedPage = () => import(/* webpackChunkName: "reviewed-page" */ '@/pages/tender/signup-manage/enterprise/consumable/reviewed-page');
// // const ConsumableComparePage = () => import(/* webpackChunkName: "compare-page" */ '@/pages/tender/signup-manage/enterprise/consumable/compare-page');
// // const ConsumableHistoryPage = () => import(/* webpackChunkName: "history-page" */ '@/pages/tender/signup-manage/enterprise/consumable/history-page');
// //    报名企业端
//
//
// // 待合并
// // const DrugDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/tender/signup-manage/enterprise/drug/detail');
// // const DrugAduit = () => import(/* webpackChunkName: "aduit" */ '@/pages/tender/signup-manage/enterprise/drug/aduit');
// // 待合并
// // const ConsumableDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/tender/signup-manage/enterprise/consumable/detail');
// // const ConsumableAduit = () => import(/* webpackChunkName: "aduit" */ '@/pages/tender/signup-manage/enterprise/consumable/aduit');
// // const DrugAduitList = () => import(/* webpackChunkName: "form-status" */ '@/pages/tender/signup-manage/enterprise/aduit-list');
// // const ConsumableAduitList = () => import(/* webpackChunkName: "form-status" */ '@/pages/tender/signup-manage/enterprise/aduit-list');
// // 一期不做
// // const Compare = () => import(/* webpackChunkName: "Compare" */ '@/pages/tender/signup-manage/enterprise/compare');
// // const History = () => import(/* webpackChunkName: "History" */ '@/pages/tender/signup-manage/enterprise/history');
//
// export default [
//     // 药品
//     //    管理端
//     {
//         path: 'enterprise/drug/aduit-list',
//         component: DrugList,
//         meta: {
//             title: '企业报名审核'
//         }
//     },
//     {
//         path: 'enterprise/drug/aduit-list/review',
//         component: DrugReviewingPage,
//         meta: {
//             title: '审核'
//         }
//     },
//     {
//         path: 'enterprise/drug/aduit-list/details',
//         component: DrugReviewedPage,
//         meta: {
//             title: '审核详情'
//         }
//     },
//     {
//         path: 'enterprise/drug/aduit-list/details/compare',
//         component: DrugComparePage,
//         meta: {
//             title: '比对详情'
//         }
//     },
//     {
//         path: 'enterprise/drug/aduit-list/details/history',
//         component: DrugHistoryPage,
//         meta: {
//             title: '查看历史'
//         }
//     },
//     //    报名企业端
//     {
//         path: 'enterprise/drug/sign-up',
//         component: Business,
//         meta: {
//             title: '企业报名'
//         }
//     },
//     // 耗材
//     //    管理端
//     {
//         path: 'enterprise/consumable/aduit-list',
//         component: ConsumableList,
//         meta: {
//             title: '企业报名审核'
//         }
//     },
//     {
//         path: 'enterprise/consumable/aduit-list/review',
//         component: ConsumableReviewingPage,
//         meta: {
//             title: '审核'
//         }
//     },
//     {
//         path: 'enterprise/sign-up',
//         component: SignUp,
//         meta: {
//             title: '企业报名'
//         }
//     },
//     {
//         path: 'enterprise/aduit-list/review',
//         component: Review,
//         meta: {
//             title: '审核'
//         }
//     },
//     {
//         path: 'enterprise/aduit-list/view',
//         component: View,
//         meta: {
//             title: '查看详情'
//         }
//     }
//     // {
//     //     path: 'enterprise/consumable/compare',
//     //     component: Compare,
//     //     meta: {
//     //         title: '比对详情'
//     //     }
//     // },
//     // {
//     //     path: 'enterprise/drug/compare',
//     //     component: Compare,
//     //     meta: {
//     //         title: '比对详情'
//     //     }
//     // },
//     // {
//     //     path: 'enterprise/consumable/history',
//     //     component: History,
//     //     meta: {
//     //         title: '查看历史'
//     //     }
//     // },
//     // {
//     //     path: 'enterprise/drug/history',
//     //     component: History,
//     //     meta: {
//     //         title: '查看历史'
//     //     }
//     // },
//     // {
//     //     path: 'enterprise/consumable/aduit-list/aduit',
//     //     component: ConsumableAduit,
//     //     meta: {
//     //         title: '审核',
//     //         display: 'none'
//     //     }
//     // },
//     // {
//     //     path: 'enterprise/consumable/aduit-list/aduit/detail',
//     //     component: ConsumableDetail,
//     //     meta: {
//     //         title: '比对详情',
//     //         display: 'none'
//     //     }
//     // },
//     // {
//     //     path: 'enterprise/drug/aduit-list/aduit/detail',
//     //     component: DrugDetail,
//     //     meta: {
//     //         title: '比对详情',
//     //         display: 'none'
//     //     }
//     // },
//     // {
//     //     path: 'enterprise/drug/aduit-list/aduit',
//     //     component: DrugAduit,
//     //     meta: {
//     //         title: '审核',
//     //         display: 'none'
//     //     }
//     // }
// ];
