/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-11
 * @lastModifiedBy yanghy
 * @lastModifiedTime 18:14
 * @route
 * @description 招标管理
 */

const TenderList = () => import(/* webpackChunkName: "tender-list" */ '@/pages/tender/tendering-manage/list');

export default [
    {
        path: 'tendering-manage/list',
        component: TenderList,
        meta: {
            title: '招标项目管理'
        }
    }
];
