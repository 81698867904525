/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-07-26 14:53:12
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-12-30 15:19:51
 * @Description: 违规处罚监管/违规处罚/违规处罚查询
 */

const List = () => import(/* webpackChunkName: "list" */ '@/pages/mgr/project-analysis/illegal-punish/query/list');
const Detail = () => import(/* webpackChunkName: "detail" */ '@/pages/mgr/project-analysis/illegal-punish/query/detail');

export default [
    {
        path: 'query/list',
        component: List,
        meta: {
            title: '违规处罚查询'
        }
    },
    {
        path: 'query/list/detail',
        component: Detail,
        meta: {
            title: '查看详情',
            display: 'none'
        }
    }
];
