/**
 * @auther qiu
 * @date 20190322
 * @description  列表行组件
 */
 
<template>
    <div
        :class="className"
        :style="styles"
        @click="handleClick">
        <span
            v-if="index"
            class="vw-li_flag"
            :style="flagStyle"
            :data-flag="index" />
        <!-- 主内容 -->
        <div
            :class="`vw-li_con ${contentClass}`"
            :style="contentStyle">
            <slot />
        </div>
        <!-- 尾部内容 -->
        <div class="vw-li_right">
            <slot name="right" />
        </div>
    </div>
</template>
<script>
/* eslint-disable */ 
export default {
    name: 'VwLi',
    props: {
        // 序号
        index: {},
        // 类型  box , round 
        type: {},
        // 标识样式
        flagStyle: {
            type: Object
        },
        // 自定义样式
        styles: {
            type: Object
        },
        // 内容部分 样式
        contentStyle: {
            type: Object
        },
        // 内容部分 className
        contentClass: {
            type: String
        }
     
    },
    computed: {
        className() {
            let str = 'vw-li';
            const { type, index } = this;
            if (type) str += ` vw-li_type--${type}`;
            if (type === 'box' && index % 2) str += '  vw-li--odd';
            return str;
        }
    },
    methods: {
        handleClick(e) {
            this.$emit('click', e);
        }
    }
};
</script>
<style lang="less" scoped>
.vw-li {
    position: relative;
    display: flex;
    align-items: center;
    font-size: inherit;
    color: #fff;
    > .vw-li_flag::before {
        content: attr(data-flag);
    }
    .vw-li_con {
        flex: 1 1 auto;
        word-wrap: break-word;
        word-break: break-all;
    }
    .vw-li_right {
        display: inline-flex;
        flex: 0 none;
       
        margin-left: 5px;
        margin-right: 5px;
    }
    .vw-li_right:empty {
        margin-left: 0px;
        margin-right: 0px;
    }

    // box round
    &.vw-li_type--box > .vw-li_flag,
    &.vw-li_type--round > .vw-li_flag {
        justify-content: center;
        align-items: center;
        border: 1px solid @primary-green;
        font-size: 80%;
        display: inline-flex;
    }
    // type box
    &.vw-li_type--box {
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 6px;
        font-size: 12px;
        & > .vw-li_flag {
            min-width: 16px;
            min-height: 16px;
            margin-right: 10px;
            &[data-flag="1"],
            &[data-flag="2"],
            &[data-flag="3"] {
                background-color: @bg-li-flag-green;
            }
        }
    }
    &.vw-li--odd {
        background-color: @bg-green-1;
    }
    // 圆
    &.vw-li_type--round {
        padding-left: 17px;
        margin-top: 10px;
        background: @bg-green-1;
        & > .vw-li_flag {
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            min-width: 24px;
            min-height: 24px;
            background-color: @bg-li-flag-green;
            z-index: 9;
        }
        > .vw-li_con {
            min-height: 30px;
            padding-left: 20px;
            border-left: 1px solid @primary-green;
            border-right: 1px solid @primary-green;
        }
    }
    // 无序列表
    &.vw-li_type--list {
        padding-left: 5px;
        margin-top: 10px;
        background-color: @bg-green-1;
        &::before {
            content: "";
            width: 8px;
            height: 8px;
            border: 1px solid @primary-green;
            border-radius: 50%;
            margin-right: 8px;
        }
        & > .vw-li_flag {
            display: none;
        }
    }
}
</style>
