var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "vw-progress", style: { background: _vm.background } },
      [
        _c(
          "div",
          {
            staticClass: "vw-progress_flag",
            style: {
              background: _vm.valueBg,
              width: Math.min(_vm.value, 100) + "%"
            }
          },
          [_c("span")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "vw-progress_value" }, [
          _vm._v("\n            " + _vm._s(_vm.currentValue) + "%\n        ")
        ]),
        _vm._v(" "),
        _vm.type === "grid"
          ? _c(
              "div",
              { staticClass: "vw-progress_i" },
              _vm._l(_vm.eq, function(i) {
                return _c("i", {
                  key: i,
                  style: { borderLeftColor: _vm.gridColor }
                })
              }),
              0
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }