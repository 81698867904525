/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-02-20 14:28:28
 * @Last Modified by: yanshuai
 * @Last Modified time: 2020-10-15 11:10:14
 * @Description: 中选耗材采购进度
 * @Route: Route
 */
const List = () => import(/* webpackChunkName: "selected-list" */ '@/pages/mgr/procurement-project/com-purchase-analysis/selected/list');

export default [
    {
        path: 'selected/list',
        component: List,
        meta: {
            title: '中选耗材采购进度'
        }
    }
];