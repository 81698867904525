import View from '@/components/view';

// 备案采购-药品-管理机构
const List = () => import(/* webpackChunkName: "recordUrchase" */ '@/pages/bdc/med-relationship/list');

const routes = [
    {
        path: 'med-relationship/list',
        component: List,
        meta: {
            title: '经办关系管理'
        }
    }
];

export default routes;