/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-10-29 18:52:50
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-10-29 19:16:07
 * @Description: 专题分析/结构分析总路由
 */
const List = () => import(/* webpackChunkName: "structural-analysis-list" */ '@/pages/mgr/project-analysis/structural-analysis/list');
const Drug = () => import(/* webpackChunkName: "structural-analysis-drug-analysis" */ '@/pages/mgr/project-analysis/structural-analysis/drug-analysis');
const Material = () => import(/* webpackChunkName: "structural-analysis-material-analysis" */ '@/pages/mgr/project-analysis/structural-analysis/material-analysis');

export default [
    {
        path: 'structural-analysis/list',
        component: List,
        meta: {
            title: '结构分析'
        }
    },
    {
        path: 'structural-analysis/list/drug-analysis',
        component: Drug,
        meta: {
            title: '药品分析',
            display: 'none'
        }
    },
    {
        path: 'structural-analysis/list/material-analysis',
        component: Material,
        meta: {
            title: '耗材分析',
            display: 'none'
        }
    }
 
];