/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-02-20 14:47:33
 * @Last Modified by: panzw2
 * @Last Modified time: 2021-02-02 10:19:53
 * @Description: 数据分析规则设置
 * @Route: Route
 */

import View from '@/components/view';
// 设置非中选品种
import ChosenDrugManage from './chosen-drug-manage';
// 机构约定量配置
import OrgAppointConfig from './org-appoint-config';


const monitoringSettings = () => import(/* webpackChunkName: "settings" */ '@/pages/mgr/procurement-project/com-rule-setting/monitoring-settings/settings');

export default [
    {
        path: 'com-rule-setting',
        component: View,
        meta: {
            title: '数据分析规则设置'
        },
        children: [
            ...ChosenDrugManage,
            ...OrgAppointConfig,
            {
                path: 'monitoring-settings',
                component: monitoringSettings,
                meta: {
                    title: '耗材带量参数设置'
                }
            }
        ]
    }
];