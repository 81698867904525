/**
 * @auther qiu
 * @date 20190322
 * @description  进度条
 */
<template>
    <div>
        <div
            class="vw-progress"
            :style="{background}">
            <div
                class="vw-progress_flag"
                :style="{background:valueBg,width:(Math.min(value,100)+'%')}">
                <span />
            </div>
            <div class="vw-progress_value">
                {{ currentValue }}%
            </div>
            <div
                v-if="type === 'grid'"
                class="vw-progress_i">
                <i
                    v-for="i in eq"
                    :key="i"
                    :style="{borderLeftColor:gridColor}" />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
/**
 * @file 进度条
 * @author qiuwt
 */
export default {
    name: 'VwProgress',
    props: {
        // 值
        value: {
            type: [Number, String],
            default: 0
        },
        // 类型 grid 带格子的
        type: {},
        // 分几格
        eq: {
            type: Number,
            default: 10
        },
        /**
         * 格子模式下显示的颜色
         */
        gridColor: {
            type: String,
            default: 'rgba(16, 83, 137, 0.5)'
        },
        // 进度条的样式
        valueBg: {
            type: String,
            default: 'linear-gradient(to right, #f19b03, #41c9c9)'
        },
        //  主体背景
        background: {
            type: String,
            default: 'rgba(82, 255, 255, .05)'
        },
        /**
         * 保留小数
         */
        decimele: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {};
    },
    computed: {
        currentValue() {
            return this.value > 0 ? Number(this.value).toFixed(this.decimele) * 1 : 0;
        }
    },
    created() {},
    methods: {}
};
</script>

<style lang="less" scoped>
.vw-progress {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 100px;
    color: @color-white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: @bg-green-1;
    font-size: inherit;
    padding-right: 2%;
    .vw-progress_i,
    .vw-progress_flag {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .vw-progress_value {
        text-align: center;
        position: relative;
        z-index: 3;
    }
    .vw-progress_i {
        display: flex;
        z-index: 2;
        > i {
            flex: 1 1 auto;
        }
        > i:not(:first-of-type) {
            border-left: 2px solid;
        }
    }
    .vw-progress_flag{
        transition: all .3s;
        width: 0%;
    }
}
</style>
