/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-08-22 15:33:47
 * @Last Modified by: maozg
 * @Last Modified time: 2019-09-05 14:09:39
 * @Description: 业务监管/监测预警/结算回款不达标预警
 */

const List = () => import(/* webpackChunkName: "settlement-moneyback-unstandard-list" */ '@/pages/mgr/business/monitor-warning/settlement-moneyback-unstandard/list');
const ListProvince = () => import(/* webpackChunkName: "settlement-moneyback-unstandard-list" */ '@/pages/mgr/business/monitor-warning/settlement-moneyback-unstandard/list-province');
const Detail = () => import(/* webpackChunkName: "settlement-moneyback-unstandard-list" */ '@/pages/mgr/business/monitor-warning/settlement-moneyback-unstandard/detail');

export default [
    {
        path: 'settlement-moneyback-unstandard/list',
        component: List,
        meta: {
            title: '结算回款不达标预警'
        }
    },
    {
        path: 'settlement-moneyback-unstandard/list-province',
        component: ListProvince,
        meta: {
            title: '各省结算回款不达标预警',
            display: 'none'
        }
    },
    {
        path: 'settlement-moneyback-unstandard/detail',
        component: Detail,
        meta: {
            title: ' 医疗机构结算回款率详情',
            display: 'none'
        }
    }
];
