export default {
    // 后端服务
    serverUrl: 'http://172.18.30.61:5051',
    // serverUrl: 'http://172.17.23.96:8082',
    // oss
    ossUrl: 'http://oss.heisea.cn',
    // ossUrl: 'http://172.17.27.201:8080',
    // 签章相关 - 测试地址
    serverAddress: 'http://47.105.180.60:8180/ESS/api/serviceForAip/'
    // serverAddress: 'http://172.17.10.110:8080/ESS/api/serviceForAip/'
};
