var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "height-full", class: { "main-container": _vm.publicFlag } },
    [
      _c(
        "keep-alive",
        { attrs: { include: ["CmsUnitList", "MgrStructuralList"] } },
        [_vm.loading ? _c("router-view") : _vm._e()],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }