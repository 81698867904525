import View from '@/components/view';

// 短缺信息/备案采购管理-耗材-医疗机构
const shortageInfoReportList = () => import(/* webpackChunkName: "shortageInfoReportList" */ '@/pages/trade/shortage-message/consumable/shortage-info-report-list');
const shortageInfoReportListAdd = () => import(/* webpackChunkName: "shortageInfoReportListAdd" */ '@/pages/trade/shortage-message/consumable/shortage-info-report-list-add');
const filingProcurementManageList = () => import(/* webpackChunkName: "filingProcurementManageList" */ '@/pages/trade/shortage-message/consumable/filing-procurement-manage-list');
const filingProcurementAdd = () => import(/* webpackChunkName: "filingProcurementAdd" */ '@/pages/trade/shortage-message/consumable/filing-procurement-add');
const filingProcurementEdit = () => import(/* webpackChunkName: "filingProcurementAdd" */ '@/pages/trade/shortage-message/consumable/filing-procurement-add');

const routes = [
    {
        path: 'shortage-message/consumable',
        component: View,
        meta: {
            title: '短缺信息/备案采购管理'
        },
        children: [
            {
                path: 'shortage-message/list',
                component: shortageInfoReportList,
                meta: {
                    title: '短缺耗材信息填报'
                }
            },
            {
                path: 'shortage-message/list/add',
                component: shortageInfoReportListAdd,
                meta: {
                    title: '新增',
                    display: 'none'
                }
            },
            {
                path: 'shortage-message/filing-procurement-manage/list',
                component: filingProcurementManageList,
                meta: {
                    title: '耗材备案采购管理'
                }
            },
            {
                path: 'shortage-message/filing-procurement-manage/list/add',
                component: filingProcurementAdd,
                meta: {
                    title: '新增',
                    display: 'none'
                }
            },
            {
                path: 'shortage-message/filing-procurement-manage/list/edit',
                component: filingProcurementEdit,
                meta: {
                    title: '编辑',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;