<template>
    <div class="card-box"
         @click="handleClick">
        <div v-if="title"
             class="card-title">
            <div class="title-box">
                <span v-if="!link"
                      class="title">{{ title }}</span>
                <a v-else
                   :href="link"
                   :title="link"
                   class="link"
                   target="_blank">{{ title }}<i class="link-icon" /></a>
                <span v-if="ps"
                      class="ps">{{ ps }}</span>
                <span v-if="tips"
                      class="tips">{{ tips }}</span>
                <span class="tips">
                    <slot name="tips" /></span>
            </div>
        </div>
        <div class="card-body">
            <slot />
        </div>
    </div>
</template>

<script>
/* eslint-disable */ 
export default {
    name: 'VwCardBox',
    props: {
        // 左侧标题
        title: {
            type: String
            // required: true //部分不需要标题 如 弹窗
        },
        // 标题为链接时跳转地址
        link: String,
        // 右侧提示
        tips: String,
        // 左侧标题后边的备注
        ps: String
    },
    data() {
        return {};
    },
    methods: {
        handleClick() {
            this.$emit('click', this);
        }
    }
};
</script>

<style lang="less" scoped>
.card-box {
    width: 100%;
    height: 100%;
    background: @bg-green-1;
    // border: 12px solid transparent;
    border-image: url('~@/images/visual/corner.png') 12 12 repeat;
    border-image-outset: 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 23px;
    padding: 0;
}
.card-title {
    border-bottom: 1px solid @bg-green-6;
    width: 100%;
    height: 33px;
}
.card-body {
    flex: 1;
    overflow: hidden;
}
.title-box {
    color: @primary-green;
    margin-bottom: 12px;
    position: relative;
    vertical-align: bottom;
    height: 50px;
}
.title {
    font-size: 16px;
    font-weight: 700;
    margin-right: 10px;
    line-height: 1;
    position: absolute;
    left: 0;
    margin: 4px;
}
.link {
    color: @primary-green;
    margin-right: 5px;
    line-height: 1;
    font-size: 16px;
    font-weight: 700;

    &:hover {
        opacity: 0.8;
    }
    .link-icon {
        display: inline-block;
        vertical-align: middle;
        width: 14px;
        height: 14px;
        margin-left: 5px;
        background: url('~@/images/visual/link_small.png') no-repeat center / cover;
    }
}
.ps,
.tips {
    font-size: 12px;
}
.tips {
    position: absolute;
    right: 0;

    margin: 11px;
}
</style>