/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-07-26 11:18:42
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-12-15 15:36:35
 * @Description: 监管系统页面总路由
 */

import View from '@/pages/mgr/view';
import MgrBusiness from './business';
import TenderStatDrug from './tender-stat-drug';
import TenderStatCons from './tender-stat-cons';
import ProjectAnalysis from './project-analysis';
// 带量采购专题
import ProcurementProject from './procurement-project';
import MonitoringWarning from './monitoring-wraning';

//  药品招采统计分析
import drugTatisticalAnalysis from './full-amount/drug-tatistical-analysis';
//  耗材招采统计分析
import consumablesTatisticalAnalysis from './full-amount/consumables-tatistical-analysis';

// 吉林监管大屏参数配置
const VisualSettings = () => import(/* webpackChunkName: "settings" */ '@/pages/mgr/full-amount/settings.vue');


export default [{
    path: '/mgr',
    component: View,
    meta: {
        title: '监管系统'
    },
    children: [
        ...MgrBusiness,
        ...TenderStatDrug,
        ...TenderStatCons,
        ...ProjectAnalysis,
        ...ProcurementProject,
        ...MonitoringWarning,
        {
            path: 'visual/settings',
            component: VisualSettings,
            meta: {
                title: '大屏参数配置'
            }
        },
        ...drugTatisticalAnalysis,
        ...consumablesTatisticalAnalysis
    ]
}];