import Fetch from '@/utils/fetch';

/**
 * 违规处罚监管
 */
// 违规处罚处理查询
export const punishQueryByCode = params => (
    Fetch({
        url: '/api/mgr/violation/get',
        params
    })
);
/**
 * 监管参数设置
 */
// 监测预警设置查询
export const getThreshold = params => (
    Fetch({
        url: '/api/mgr/threshold/get',
        params
    })
);
// 提交采购警报阈值
export const savePurchase = params => (
    Fetch({
        url: '/api/mgr/threshold/savePurchase',
        params,
        method: 'post'
    })
);
// 提交配送率警报阈值
export const saveDelivery = params => (
    Fetch({
        url: '/api/mgr/threshold/saveDelivery',
        params,
        method: 'post'
    })
);
// 提交收货率警报阈值
export const saveReceive = params => (
    Fetch({
        url: '/api/mgr/threshold/saveReceive',
        params,
        method: 'post'
    })
);
// 提交回款警报阈值
export const savePayback = params => (
    Fetch({
        url: '/api/mgr/threshold/savePayback',
        params,
        method: 'post'
    })
);
// 提交价格异常阈值
export const savePrice = params => (
    Fetch({
        url: '/api/mgr/threshold/savePriceLimit',
        params,
        method: 'post'
    })
);
// 获取文件信息

export const getFile = queryData => (
    Fetch({
        url: queryData.url,
        params: queryData.params
    })
);

// 获取地区
export const queryRegion = params => (
    Fetch({
        url: '/web/mgr/admdvs/query',
        params,
        method: 'get'
    })
);

export const queryOrgByName = '/web/mgr/org/queryOrgByName';

// 删除黑名单违规类型
export const deleteDetails = params => (
    Fetch({
        url: '/web/mgr/violationType/delete',
        method: 'post',
        params
    })
);
// 删除黑名单违规
export const deletepunhTitl = params => (
    Fetch({
        url: '/web/mgr/volaPunh/delete',
        method: 'post',
        params
    })
);
// 发布黑名单违规
export const releasepunhTitl = params => (
    Fetch({
        url: '/web/mgr/volaPunh/rls',
        method: 'post',
        params
    })
);
// 执行黑名单
export const carryProdBlack = params => (
    Fetch({
        url: '/web/mgr/violation/carryProdBlack',
        method: 'post',
        params
    })
);
// 移出黑名单
export const removeProdBlack = params => (
    Fetch({
        url: '/web/bdc/bkls/removeProdBlack',
        method: 'post',
        params
    })
);

// 获取 耗材 带量采购 下啦
export const getConsumablesList = params => (
    Fetch({
        url: '/web/mgr/purcitemmcs/selectList',
        method: 'post',
        params
    })
);

// 手工新增确定黑名单
export const bklscheck = params => (
    Fetch({
        url: '/web/bdc/bkls/check',
        method: 'post',
        params
    })
);

// 获取地区接口
export const getRegnItemCountList = params => (
    Fetch({
        url: '/web/mgr/item/mcs/regnItemCountList',
        method: 'post',
        params
    })
);


// 耗材-带量采购项目监管
export const getMcsAnalysis = params => (
    Fetch({
        url: '/web/mgr/item/mcs/analysis',
        method: 'post',
        params
    })
);

// 耗材-保存中选产品
export const getSaveItemMcs = params => (
    Fetch({
        url: '/web/mgr/purcitemmcs/saveItemMcs',
        method: 'post',
        params
    })
);


// 耗材-保存耗材参数配置
export const getSaveMcsConf = params => (
    Fetch({
        url: '/web/mgr/mcs/conf/saveMcsConf',
        method: 'post',
        params
    })
);

// 耗材-获取参数配置信息
export const getMcsConfInfo = params => (
    Fetch({
        url: '/web/mgr/mcs/conf/getMcsConfInfo',
        method: 'post',
        params
    })
);

// 设置中选耗材-项目下拉选
export const getSelectList = params => (
    Fetch({
        url: '/web/mgr/purcitemmcs/selectList',
        method: 'post',
        params
    })
);


// 耗材-采购进度明细查询-执行进度
export const getDayDiff = params => (
    Fetch({
        url: '/web/mgr/item/mcs/getDayDiff',
        method: 'post',
        params
    })
);


// 耗材-设置耗材约定量-修改约定量配置
export const getUpdateMedinsRpup = params => (
    Fetch({
        url: '/web/mgr/medinsItem/updateMedinsRpup',
        method: 'post',
        params
    })
);


// 耗材-设置耗材约定量-约定量导入模板下载
export const getImportModel = params => (
    Fetch({
        url: '/web/mgr/medinsItem/importModel',
        method: 'post',
        params 
    })
);

// 耗材-设置耗材约定量-导出
export const getImportRpupcnt = params => (
    Fetch({
        url: '/web/mgr/medinsItem/importRpupcnt',
        method: 'post',
        params
    })
);

// 耗材-中选产品维护-中选产品导入模板下载
export const getPurcitemmcsImportModel = params => (
    Fetch({
        url: '/web/mgr/purcitemmcs/importModel',
        method: 'post',
        params
    })
);

// 耗材-中选产品维护-中选产品导入
export const getPurcitemmcsImportItemMcs = params => (
    Fetch({
        url: '/web/mgr/purcitemmcs/importItemMcs',
        method: 'post',
        params
    })
);

// 国家下发确认黑名单
export const bklsconfirm = params => (
    Fetch({
        url: '/web/bdc/bkls/confirm',
        method: 'get',
        params
    })
);

// 温馨提示获取天数
export const getCopywriting = params => (
    Fetch({
        url: '/web/mgr/shipShppPara/getCopywriting',
        method: 'get',
        params
    })
);

// 获取监测预警阈值
export const getWarning = params => (
    Fetch({
        url: '/web/mgr/warnIndex/querryMnitWarn',
        method: 'post',
        params
    })
);

// 保存监测预警阈值
export const saveWarning = params => (
    Fetch({
        url: '/web/mgr/warnIndex/updateMnitWarn',
        method: 'post',
        params
    })
);

// 药品采购异常监测预警头部
export const selelctPurchaseWarnNum = params => (
    Fetch({
        url: '/web/mgr/purchaseWarn/selelctPurchaseWarnNum',
        method: 'post',
        params
    })
);

// 药品供应异常监测预警头部
export const querryHeadWarnCount = params => (
    Fetch({
        url: '/web/mgr/supplyWarn/querryHeadWarnCount',
        method: 'post',
        params
    })
);

// 监测预警首页
export const fetchIndexWarnCnt = params => (
    Fetch({
        url: '/web/mgr/warnIndex/querryWarnCount',
        method: 'post',
        params
    })
);

// 修改耗材集采前加权平均价
export const updateAvePric = params => (
    Fetch({
        url: '/web/mgr/purcitemmcs/updateAvePric',
        method: 'post',
        params
    })
);

//  新增中选耗材
export const savemsc = params => (
    Fetch({
        url: '/web/mgr/purcitemmcs/saveItemMcs',
        method: 'post',
        params
    })
);
