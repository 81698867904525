/**
 * @author lina
 * @email lina5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy lina
 * @lastModifiedTime 11:38
 * @route
 * @description 配送方案管理
 */

const ExpressSearch = () => import(/* webpackChunkName: "regulator-list" */ '@/pages/tender/express-manage/regulator-org/list');
const ExpressAdjust = () => import(/* webpackChunkName: "adjust" */ '@/pages/tender/express-manage/regulator-org/adjust');
const ExpressForce = () => import(/* webpackChunkName: "force" */ '@/pages/tender/express-manage/regulator-org/force-adjust');
const ExpressDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/tender/express-manage/components/common-detail');
const ExpressLog = () => import(/* webpackChunkName: "detail" */ '@/pages/tender/express-manage/regulator-org/adjust-log');

export default [
    {
        path: 'express-manage/regulator-org/list',
        component: ExpressSearch,
        meta: {
            title: '配送方案查询'
        }
    },
    {
        path: 'express-manage/regulator-org/adjust',
        component: ExpressAdjust,
        meta: {
            title: '配送签约调整',
            display: 'none'
        }
    },
    {
        path: 'express-manage/regulator-org/adjust/force-adjust',
        component: ExpressForce,
        meta: {
            title: '处理',
            display: 'none'
        }
    },
    {
        path: 'express-manage/regulator-org/adjust/log',
        component: ExpressLog,
        meta: {
            title: '调整日志',
            display: 'none'
        }
    },
    {
        path: 'express-manage/regulator-org/adjust/adjust-detail',
        component: ExpressDetail,
        meta: {
            title: '查看',
            display: 'none'
        }
    }
];
