/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 16:51:39
 * @Last Modified by: caoyp
 * @Last Modified time: 2020-12-23 17:33:34
 * @Description: 公共请求封装
 * @Route: Route
 */
import Axios from 'axios';
import { portalMessageSend } from '@ncp-web/portal-communication';
import ConfigLocal from '@/config/config-local';
import ConfigFirst from '@/config/config-first';
import ConfigFive from '@/config/config-five';
import ConfigUpms from '@/config/config-upms';
import { getUrlParame, headerConfig } from '@/utils/utils';
// import urlArr from '@/utils/api-data';

const { MODE } = process.env;

/*
 * @Description: 针对不同环境根据MODE来区分走不同的配置文件
 * upms: 对接upms
 * first: 对接一包
 * five: 对接五包
 * local: 走咱们自己包
 */
let Config = '';
switch (MODE) {
case 'upms': {
    Config = ConfigUpms;
    break;
}
case 'first': {
    Config = ConfigFirst;
    break;
}
case 'five': {
    Config = ConfigFive;
    break;
}
default: {
    Config = ConfigLocal;
    break;
}
}
const fetch = async config => {
    const { App } = window;
    // 项目id
    const tenditmId = getUrlParame('tenditmId');
    // 模块code
    const gCode = getUrlParame('gCode');
    const {
        method, params, independent, timeout, ...other
    } = config;
    let { url } = config;

    const baseUrls = Config.baseUrl;
    
    const timestamp = new Date().getTime();
    const systemId = window.localStorage.getItem('system_id');
    // 创建axios实例
    const axiosIns = Axios.create();
    // 多服务拼接
    if (Config.baseOss) {
        const arr = Object.keys(Config.baseOss);
        for (let i = 0; i < arr.length; i += 1) {
            const key = arr[i];
            const val = Config.baseOss[key];
            const reg = new RegExp(`^${key}`);
            if (url.match(reg)) {
                url = val + url;
                break;
            }
        }
    }
    
    // 防xsrf攻击设置
    const headers = headerConfig(url);
    let options = {
        headers,
        baseURL: baseUrls,
        url,
        ...other,
        timeout: timeout || 1 * 60 * 1000
    };
    options.url = url;
    
    let tenditmType;
    // 项目类型 dTender 为药品招采 cTender 为耗材招采
    // 对接UPMS systemId：1316997647234819502 为药品招采；systemId：1316997647234819503 为耗材招采
    if (gCode === 'dTender' || gCode === 'dTrans' || systemId === '1316997647234819502') {
        tenditmType = '1';
    } else if (gCode === 'cTender' || gCode === 'cTrans' || systemId === '1316997647234819503') {
        tenditmType = '2';
    }
    // 增加请求参数
    let paramObj;
    if (Array.isArray(params)) {
        paramObj = params;
    } else if (tenditmId) {
        // 招采报量模块传rupuCntItemId
        const reg = new RegExp('/web/rupu');
        if (url.match(reg) && !url.match('/web/rupu/item/info/getItemUseScp')) {
            paramObj = {
                ...params,
                rupuCntItemId: tenditmId,
                tenditmType
            };
        } else {
            paramObj = {
                ...params,
                tenditmId,
                tenditmType
            };
        }
    } else {
        paramObj = {
            ...params,
            tenditmType
        };
    }
    // 区分post 请求，get 请求
    if (method === 'post') {
        // 对接UPMS '/auth' 单独处理
        if (url.match(/^\/auth/) !== null) {
            options = Object.assign({}, options, { method: 'post', params: paramObj });
        } else {
            options = Object.assign({}, options, { method: 'post', data: paramObj });
        }
    } else {
        options = Object.assign(
            {},
            options,
            {
                method: 'get',
                params: {
                    ...paramObj,
                    timestamp
                }
            }
        );
    }

    const response = await axiosIns(options).then(res => {
        const headFlag = res.headers['hx-user-source'];
        // isImplant core:对接一包标识  public:对接五包标识
        const { isImplant } = App.$store.state;
        if (isImplant === '' && (headFlag === 'core' || headFlag === 'public')) {
            App.$store.commit('SET_ISIMPLANT_FLAG', headFlag);
        }
        const { code, message } = res.data;
        // 自己包登录失效code:161099, 一包门户登录失效code:-4,  一包门户登录失效code:161998
        if (code === 161099 || code === -4 || code === 161998) {
            return code;
        }
        // independent 存在做单独异常处理，其余走统一业务处理
        if (independent) return res.data;
        // code不为0视为错误
        if (code !== 0) {
            App.$message({
                message,
                type: 'error'
            });
            return false;
        }
        return res.data;
    }).catch(err => {
        // 对接UPMS异常状态码处理
        if (err.response && err.response.status === 401) {
            localStorage.setItem('token', '');
            App.$router.push({
                path: '/login',
                query: {
                    path: App.$route.fullPath
                }
            });
            return false;
        } if (err.response && err.response.status === 426) {
            App.$message({
                type: 'error',
                message: err.response.data.msg,
                showClose: true
            });
            return false;
        } if (err.response && err.response.status === 404) {
            App.$message.error('未找到对应接口');
            return false;
        }
        // 错误内容提示
        const errData = err.response ? err.response.data : '';
        const env = process.env.NODE_ENV;
        // 非开发环境提示 //(-100, "业务异常")(-200, "系统异常")
        if (env !== 'development' && errData && errData.code === -2) {
            // 超时
            App.$message.error('接口请求超时！');
            return false;
        } if (env !== 'development' && errData &&
            (errData.code === -200 || errData.code === -1)) {
            // 系统繁忙
            App.$message.error('系统服务异常');
            return false;
        }
        // 错误内容提示
        if (errData && typeof errData === 'string') {
            App.$message({
                type: 'error',
                message: errData,
                showClose: true
            });
        } else if (errData && typeof errData === 'object' && errData.msg) {
            App.$message({
                type: 'error',
                message: errData.msg,
                showClose: true
            });
        } else {
            App.$message.error('系统异常');
        }
        return false;
    });
    // 登录过期机制
    if (response) {
        switch (response) {
        // 自己包登录过期
        case 161099:
            App.$message.error('当前登录已失效，请重新登录');
            localStorage.setItem('token', '');
            App.$router.push({
                path: '/login'
            });
            break;
        // 对接五包登录过期，清除token，跳转到五包登录页面
        case 161998:
            App.$message.error('当前登录已失效，请重新登录');
            localStorage.setItem('token', '');
            localStorage.setItem('refreshToken', '');
            localStorage.setItem('accountType', '');
            // loginUrl相关配置请手动去config-five下config.login.js中修改
            window.location.href = window.g.loginUrl;
            break;
        // 对接一包门户系统，退出消息发送
        case -4:
            portalMessageSend.notifySessionTimeout({ content: 'session timeout' });
            break;
        default:
            break;
        }
    }
    return response;
};

export default fetch;
