/*
 * @Author: panzw2
 * @Email: panzw2@guahao.com
 * @Date: 2020-11-28 19:11:55
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-11-28 17:56:52
 * @Description: 综合监管/履约评分设置
 */
const List = () => import(/* webpackChunkName: "performance-score-set-list" */ '@/pages/mgr/project-analysis/provincial-drug-price/list');

export default [
    {
        path: 'provincial-drug-price/list',
        component: List,
        meta: {
            title: '各省历年采购价'
        }
    }
];