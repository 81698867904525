/**
 * @author lina
 * @email lina5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy lina
 * @lastModifiedTime 11:38
 * @route
 * @description 配送方案管理
 */

const ExpressConfirm = () => import(/* webpackChunkName: "confirm" */ '@/pages/tender/express-manage/express-org/confirm');
const ExpressAffirm = () => import(/* webpackChunkName: "affirm" */ '@/pages/tender/express-manage/express-org/affirm');
const ExpressSure = () => import(/* webpackChunkName: "adjust-sure" */ '@/pages/tender/express-manage/express-org/adjust-sure');
const ExpressLog = () => import(/* webpackChunkName: "express-log" */ '@/pages/tender/express-manage/express-org/adjust-log');
const ExpressDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/tender/express-manage/components/common-detail');

export default [
    {
        path: 'express-manage/express-org/confirm',
        component: ExpressConfirm,
        meta: {
            title: '配送方案确认',
            display: 'none'
        }
    },
    {
        path: 'express-manage/express-org/affirm',
        component: ExpressAffirm,
        meta: {
            title: '配送方案调整确认',
            display: 'none'
        }
    },
    {
        path: 'express-manage/express-org/affirm/adjust-sure',
        component: ExpressSure,
        meta: {
            title: '确认',
            display: 'none'
        }
    },
    {
        path: 'express-manage/express-org/affirm/adjust-log',
        component: ExpressLog,
        meta: {
            title: '调整日志',
            display: 'none'
        }
    },
    {
        path: 'express-manage/express-org/affirm/adjust-detail',
        component: ExpressDetail,
        meta: {
            title: '查看',
            display: 'none'
        }
    }
];
