(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("vue-router"), require("element-ui"));
	else if(typeof define === 'function' && define.amd)
		define(["vue", "vue-router", "element-ui"], factory);
	else if(typeof exports === 'object')
		exports["hsa"] = factory(require("vue"), require("vue-router"), require("element-ui"));
	else
		root["hsa"] = factory(root["vue"], root["vue-router"], root["element-ui"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__184__, __WEBPACK_EXTERNAL_MODULE__288__) {
return 