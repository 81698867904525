// 挂网目录查询-药品-管理机构
const hangingManagement = () => import(/* webpackChunkName: "recordPurchase" */ '@/pages/trade/hanging-management/drug/hanging-management');

const routes = [
    {
        path: 'hanging-management/drug/hanging-management',
        component: hangingManagement,
        meta: {
            title: '挂网目录查询'
        }
    }
];

export default routes;