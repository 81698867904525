/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 17:05:28
 * @Last Modified by: caoyp
 * @Last Modified time: 2021-02-01 18:10:01
 * @Description: 空路由容器
 * @Route: Route
 */
<template>
    <div class="height-full"
         :class="{ 'main-container': publicFlag }">
        <keep-alive :include="['CmsUnitList','MgrStructuralList']">
            <router-view v-if="loading" />
        </keep-alive>
    </div>
</template>

<script>
import { queryRegion, getConsumablesList } from '@/api/mgr';

export default {
    data() {
        return {
            regnLoading: false,
            consumablesLoading: false,
            publicFlag: true,
            extFieldStr: {}
        };
    },
    computed: {
        loading() {
            return this.regnLoading && this.consumablesLoading;
        },
        applyMeunObj() {
            return window.Store.getters.applyMeunList;
        }
    },
    watch: {
        applyMeunObj: {
            handler(val) {
                this.$nextTick(() => {
                    const menuList = JSON.parse(JSON.stringify(val));
                    if (Array.isArray(menuList) && menuList.length > 0) {
                        menuList[0].children.forEach(item => {
                            if (item.name === '数据总览') {
                                menuList[0].children[0].children.forEach(subItem => {
                                    if (subItem.name === '监管首页') {
                                        localStorage.setItem('extFieldStr', subItem.extField1);
                                    }
                                });
                            }
                        });
                    }
                });
            },
            immediate: true
        }
    },
    created() {
        if (this.$route.path.indexOf('/mgr/visual/home') !== -1) {
            this.publicFlag = false;
        } else {
            this.publicFlag = true;
        }
    },
    mounted() {
        // 查询地区
        queryRegion().then(res => {
            // const menuList = JSON.parse(JSON.stringify(window.Store.getters.applyMeunList));
            // const extFieldStr = menuList[0].children[0].children[1].extField1;
            // localStorage.setItem('extFieldStr', extFieldStr); 
            this.regnLoading = true;
            if (!res || !res.data) return;
            // const { regnList, regnLv } = res.data;
            // if (Array.isArray(regnList) && regnList.length !== 0 && regnLv === '20') {
            // regnList.unshift({
            //     admdvsName: '全省',
            //     admdvs: ''
            // });
            // }
            this.$store.commit('GET_REGION_LIST', res.data);
        });
        // 耗材带量采购项目
        getConsumablesList({
            actiStas: 1
        }).then(res => {
            this.consumablesLoading = true;
            if (!res || !res.data) return;
            const { records } = res.data;
            this.$store.commit('GET_CONSUMABLESLIST_LIST', records);
        });
    }
};
</script>
