

/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 直接挂网管理/挂网结果查询
 */

const ConsumableList = () => import(/* webpackChunkName: "p-list" */ '@/pages/tender/spread-net-manage/result-query/product-org/consumable/list');
const DrugList = () => import(/* webpackChunkName: "pd-list" */ '@/pages/tender/spread-net-manage/result-query/product-org/drug/list');
const RConsumableList = () => import(/* webpackChunkName: "r-list" */ '@/pages/tender/spread-net-manage/result-query/regulator-org/consumable/list');
const RDrugList = () => import(/* webpackChunkName: "rd-list" */ '@/pages/tender/spread-net-manage/result-query/regulator-org/drug/list');

export default [
    {
        path: 'result-query/product-org/consumable/list',
        component: ConsumableList,
        meta: {
            title: '挂网结果查询'
        }
    },
    {
        path: 'result-query/product-org/drug/list',
        component: DrugList,
        meta: {
            title: '挂网结果查询'
        }
    },
    {
        path: 'result-query/regulator-org/consumable/list',
        component: RConsumableList,
        meta: {
            title: '挂网结果查询'
        }
    },
    {
        path: 'result-query/regulator-org/drug/list',
        component: RDrugList,
        meta: {
            title: '挂网结果查询'
        }
    }
];
