// 备案采购-耗材-管理机构
const recordPurchase = () => import(/* webpackChunkName: "recordPurchase" */ '@/pages/trade/record-purchase/consumable/filing-procurement-manage-list');

const routes = [
    {
        path: 'record-purchase/consumable/filing-procurement-manage-list',
        component: recordPurchase,
        meta: {
            title: '备案采购管理'
        }
    }
];

export default routes;