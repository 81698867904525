/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-02-20 14:28:40
 * @Last Modified by: yanshuai
 * @Last Modified time: 2020-10-15 11:01:48
 * @Description: 医疗机构采购进度
 * @Route: Route
 */
const List = () => import(/* webpackChunkName: "medical-procurement-list" */ '@/pages/mgr/procurement-project/com-purchase-analysis/medical-procurement/list');

export default [
    {
        path: 'medical-procurement/list',
        component: List,
        meta: {
            title: '医疗机构采购进度'
        }
    }
];