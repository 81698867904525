/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-02-20 14:28:28
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-09-13 13:48:44
 * @Description: 医疗机构中选药品采购监管
 * @Route: Route
 */
const List = () => import(/* webpackChunkName: "selected-drug-list" */ '@/pages/mgr/procurement-project/purchase-analysis/selected-drug/list');
const Detail = () => import(/* webpackChunkName: "single-selected-detail" */ '@/pages/mgr/procurement-project/purchase-analysis/selected-drug/single-selected');
const SingleDetail = () => import(/* webpackChunkName: "purchase-special-detail" */ '@/pages/mgr/procurement-project/purchase-analysis/purchase-special/single-detail');

export default [
    {
        path: 'selected-drug/list',
        component: List,
        meta: {
            title: '医疗机构中选药品采购监管'
        }
    },
    {
        path: 'selected-drug/list/single-selected',
        component: Detail,
        meta: {
            title: '单医疗机构中选药品采购监管'
        }
    },
    {
        path: 'selected-drug/list/single-selected/single-detail',
        component: SingleDetail,
        meta: {
            title: '单医疗机构单个品规采购明细'
        }
    }
];