export default {
    responseProps: {
        code: 'code', // 响应状态码
        data: 'data', // 响应数据
        message: 'message', // 响应文案信息
        successCode: 0 // 文件上传联调
    },
    responseListProps: {
        results: 'records', // 响应数组数据
        pageSize: 'size', // 页长
        pageNumber: 'current', // 页码
        totalCount: 'total', // 页面数据总量
        cursorCode: 'cursorCode' // 游标标记字段
    },
    // 文件响应数据键值
    responseFileProps: {
        fileId: 'fileId', // 文件标识字段
        fileUrl: 'fileUrl', // 文件地址
        fileName: 'originalFileName', // 文件名
        fileSuffix: 'fileExt' // 文件后缀
    },
    // PDF预览域名
    pdfFilePath: '/oss',
    // 缓存key 前缀，部署区-应用名-key，此处定义部署区+应用名
    cacheKey: 'country-hsa',
    // 加密流程方式：false，非CA 加密流程；true，CA 加密流程
    caStas: false,
    // 临时三方协议签章
    isSignature: false,
    // 预览
    filePath: '/web/trade/comp/file/query',
    // 验证码
    vcodePath: '/web/comp/vcode/image/gen',
    // 文件下载方式是否为新，默认false
    downloadFlag: true,
    // type为input类型的之后，新增字符长度限制
    inputLength: 100,
    // 列表无数据时是否展示分页信息，默认为false
    emptyPagingShow: false,
    // 当前页码大于1且records无数据时自动跳上一页
    autoPrevPage: true
};