var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "height-full main-container" },
    [
      _c(
        "keep-alive",
        {
          attrs: {
            include: [
              "CmsUnitList",
              "BdcProductOrg",
              "BdcExpressOrg",
              "MgrStructuralList",
              "ExpressSignList"
            ]
          }
        },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }