/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-02-20 14:34:31
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-11-26 12:24:27
 * @Description: 采购进度与结算分析
 * @Route: Route
 */
 
import View from '@/pages/mgr/procurement-project/purchase-analysis/view';
// 各区县采购进度与结算分析
import CountryAreaAnalysis from './country-area-analysis';
// 4+7药品采购分析
import PurchaseSpecial from './purchase-special';
// 医疗机构中选药品采购监管
import SelectedDrug from './selected-drug';

export default [
    {
        path: 'purchase-analysis',
        component: View,
        meta: {
            title: '采购项目监管'
        },
        children: [
            ...CountryAreaAnalysis,
            ...PurchaseSpecial,
            ...SelectedDrug
        ]
    }
];