
/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 目录管理/量价管理
 */

// 耗材
const ConsumableList = () => import(/* webpackChunkName: "ConsumableList" */ '@/pages/tender/catalog-manage/volume-price/consumable/list');
const ConsumableFileWrite = () => import(/* webpackChunkName: "ConsumableFileWrite" */ '@/pages/tender/catalog-manage/volume-price/consumable/file-write');
const ConsumableFileReport = () => import(/* webpackChunkName: "ConsumableFileReport" */ '@/pages/tender/catalog-manage/volume-price/consumable/file-report');


// 药品
const DrugList = () => import(/* webpackChunkName: "DrugList" */ '@/pages/tender/catalog-manage/volume-price/drug/list');
const DrugFileWrite = () => import(/* webpackChunkName: "DrugFileWrite" */ '@/pages/tender/catalog-manage/volume-price/drug/file-write');
const DrugFileReport = () => import(/* webpackChunkName: "DrugFileReport" */ '@/pages/tender/catalog-manage/volume-price/drug/file-report');

export default [
    // 耗材
    {
        path: 'volume-price/consumable/list',
        component: ConsumableList,
        meta: {
            title: '量价管理'
        }
    },
    {
        path: 'volume-price/consumable/list/file-write',
        component: ConsumableFileWrite,
        meta: {
            title: '采购量填写'
        }
    },
    {
        path: 'volume-price/consumable/list/file-write/file-report',
        component: ConsumableFileReport,
        meta: {
            title: '填报',
            display: 'none'
        }
    },
    // 药品
    {
        path: 'volume-price/drug/list',
        component: DrugList,
        meta: {
            title: '量价管理'
        }
    },
    {
        path: 'volume-price/drug/list/file-write',
        component: DrugFileWrite,
        meta: {
            title: '采购量填写'
        }
    },
    {
        path: 'volume-price/drug/list/file-write/file-report',
        component: DrugFileReport,
        meta: {
            title: '填报',
            display: 'none'
        }
    }
];
