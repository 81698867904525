/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 17:05:28
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-11-26 13:51:28
 * @Description: 空路由容器
 * @Route: Route
 */
<template>
    <router-view v-if="loading" />
</template>

<script>
import { getPurcitem } from '@/api/mgr/procurement-project';

export default {
    provide() {
        return {
            reload: this.reload
        };
    },
    data() {
        return {
            loading: false
        };
    },
    mounted() {
        this.reload();
    },
    methods: {
        reload() {
            getPurcitem({ actiStas: 1, size: 100, current: 1 }).then(res => {
                this.loading = true;
                if (!res || !res.data) return;
                const { records } = res.data;
                
                if (Array.isArray(records) && records.length > 0) {
                    this.$store.commit('GET_QUANTITY_LIST', records);
                }
            });
        }
        
        
    }
};
</script>
