/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-02-20 14:28:40
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-11-26 10:51:16
 * @Description: 4+7药品采购分析
 * @Route: Route
 */
const List = () => import(/* webpackChunkName: "purchase-special-list" */ '@/pages/mgr/procurement-project/purchase-analysis/purchase-special/list');
const SingleDetail = () => import(/* webpackChunkName: "purchase-special-detail" */ '@/pages/mgr/procurement-project/purchase-analysis/purchase-special/single-detail');
const SingleVarietiesDetail = () => import(/* webpackChunkName: "single-varieties-detail" */ '@/pages/mgr/procurement-project/purchase-analysis/purchase-special/single-varieties');

export default [
    {
        path: 'purchase-special/list',
        component: List,
        meta: {
            title: '带量采购项目监管'
        }
    },
    {
        path: 'purchase-special/list/single-varieties',
        component: SingleVarietiesDetail,
        meta: {
            title: '单品种采购进度监管'
        }
    },
    {
        path: 'purchase-special/list/single-varieties/single-detail',
        component: SingleDetail,
        meta: {
            title: '单医疗机构单个品规采购明细'
        }
    }
];