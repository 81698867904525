export default {
    // NG代理标识
    baseUrl: '/code',
    // 上传
    uploadPath: '/code/hsaTender/tps-local/web/comp/file/upload',
    // 后端服务
    serverUrl: 'http://172.18.30.61:5051',
    // oss
    ossUrl: 'http://oss.heisea.cn',
    // ossUrl: 'http://172.17.27.201:8080',
    // 签章相关 - 测试地址
    serverAddress: 'http://47.105.180.60:8180/ESS/api/serviceForAip/'
};