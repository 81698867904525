/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-12-15 11:32:08
 * @Last Modified by: xujh
 * @Last Modified time: 2020-12-30 14:42:39
 * @Description: Description
 * @Route: Route
 */
const MedicalList = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/list-medical');
const MedicalListSub = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/list-medical-sub');
const MedicalSettleDetail = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/settle-detail-medical');
const MedicalPayDetail = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/pay-detail-medical');
const MedicalTracking = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/tracking-medical');
const MedicalTrackingSub = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/tracking-medical-sub');
const TrackingDetail = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/tracking-detail');
// 配送企业
const ExpressList = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/list-express');
const ExpressSettleDetail = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/settle-detail-express');
const ExpressPayDetail = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/pay-detail-express');
const ExpressTracking = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/tracking-express');

const routesDispathInfo = [
    {
        path: 'express-list',
        component: ExpressList,
        meta: {
            title: '应收款查询' 
            
        }
    },
    {
        path: 'express-list/settle-detail',
        component: ExpressSettleDetail,
        meta: {
            display: 'none',
            title: '结算单明细' 
        }
    },
    {
        path: 'express-list/pay-detail',
        component: ExpressPayDetail,
        meta: {
            display: 'none',
            title: '支付单明细' 
        }
    },
    {
        path: 'express-tracking',
        component: ExpressTracking,
        meta: {
            title: '结算明细追踪'
        }
    },
    {
        path: 'express-tracking/detail',
        component: TrackingDetail,
        meta: {
            display: 'none',
            title: '查看'
        }
    }
];
const routesMedicalInfo = [
    {
        path: 'medical-list',
        component: MedicalList,
        meta: {
            title: '应付款查询'
        }
    },
    {
        path: 'medical-list/settle-detail',
        component: MedicalSettleDetail,
        meta: {
            display: 'none',
            title: '结算单明细'
        }
    },
    {
        path: 'medical-list/pay-detail',
        component: MedicalPayDetail,
        meta: {
            display: 'none',
            title: '支付单明细'
        }
    },
    // 医联体
    {
        path: 'medical-list-sub',
        component: MedicalListSub,
        meta: {
            title: '应付款查询'
        }
    },
    {
        path: 'medical-list-sub/settle-detail',
        component: MedicalSettleDetail,
        meta: {
            display: 'none',
            title: '结算单明细'
        }
    },
    {
        path: 'medical-list-sub/pay-detail',
        component: MedicalPayDetail,
        meta: {
            display: 'none',
            title: '支付单明细'
        }
    },
    {
        path: 'medical-tracking',
        component: MedicalTracking,
        meta: {
            title: '结算明细追踪'
        }
    },
    {
        path: 'medical-tracking-sub',
        component: MedicalTrackingSub,
        meta: {
            title: '结算明细追踪'
        }
    },
    {
        path: 'medical-tracking/detail',
        component: TrackingDetail,
        meta: {
            display: 'none',
            title: '查看'
        }
    }
];
export {
    routesDispathInfo,
    routesMedicalInfo
};