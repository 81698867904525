/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-08-22 15:33:47
 * @Last Modified by: maozg
 * @Last Modified time: 2019-09-05 14:16:46
 * @Description: 业务监管/监测预警/采购异常预警
 */

const List = () => import(/* webpackChunkName: "purchase-abnormal-list" */ '@/pages/mgr/business/monitor-warning/purchase-abnormal/list');
const Detail = () => import(/* webpackChunkName: "purchase-abnormal-list" */ '@/pages/mgr/business/monitor-warning/purchase-abnormal/detail');

export default [
    {
        path: 'purchase-abnormal/list',
        component: List,
        meta: {
            title: '采购异常预警'
        }
    },
    {
        path: 'purchase-abnormal/detail',
        component: Detail,
        meta: {
            title: '药品地市级医疗机构采购异常预警',
            display: 'none'
        }
    }
];
