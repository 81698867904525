/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-07-26 14:57:21
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-09-29 14:36:56
 * @Description: 违规处罚监管/违规处罚/违规处罚处理
 */

const List = () => import(/* webpackChunkName: "list" */ '@/pages/mgr/project-analysis/illegal-punish/handle/list');
const Add = () => import(/* webpackChunkName: "add" */ '@/pages/mgr/project-analysis/illegal-punish/handle/add');
const Edit = () => import(/* webpackChunkName: "edit" */ '@/pages/mgr/project-analysis/illegal-punish/handle/edit');
const Read = () => import(/* webpackChunkName: "read" */ '@/pages/mgr/project-analysis/illegal-punish/handle/read');

export default [
    {
        path: 'handle/list',
        component: List,
        meta: {
            title: '违规处罚处理'
        }
    },
    {
        path: 'handle/list/add',
        component: Add,
        meta: {
            title: '新增处罚',
            display: 'none'
        }
    },
    {
        path: 'handle/list/edit',
        component: Edit,
        meta: {
            title: '修改处罚',
            display: 'none'
        }
    },
    {
        path: 'handle/list/read',
        component: Read,
        meta: {
            title: '查看详情',
            display: 'none'
        }
    }

];
