/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-11-12 18:02:37
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-12-25 14:09:58
 * @Description: 产品黑名单管理总路由
 */
const List = () => import(/* webpackChunkName: "product-blacklist-manage-list" */ '@/pages/bdc/product-blacklist-manage/list');
const DrugDetail = () => import(/* webpackChunkName: "product-blacklist-manage-drug-detail" */ '@/pages/bdc/product-blacklist-manage/drug-detail');
const ConsumableDetail = () => import(/* webpackChunkName: "product-blacklist-manage-consumable-detail" */ '@/pages/bdc/product-blacklist-manage/consumable-detail');

export default [
    {
        path: 'product-blacklist-manage/list',
        component: List,
        meta: {
            title: '产品黑名单管理',
            display: 'none'
        }
    },
    {
        path: 'product-blacklist-manage/list/drug-detail',
        component: DrugDetail,
        meta: {
            title: '药品黑名单详情',
            display: 'none'
        }
    },
    {
        path: 'product-blacklist-manage/list/consumable-detail',
        component: ConsumableDetail,
        meta: {
            title: '耗材黑名单详情',
            display: 'none'
        }
    }
];
