
/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 16:52:10
 * @Last Modified by: xujh
 * @Last Modified time: 20 20-12-14 14:55:46
 * @Description: 工具函数
 * @Route: Route
 */
import CryptoJS from 'crypto-js';
import Axios from 'axios';
import Vue from 'vue';
import dayjs from 'dayjs';
import { portalMessageSend } from '@ncp-web/portal-communication';
import ConfigLocal from '@/config/config-local';
import ConfigFirst from '@/config/config-first';
import ConfigFive from '@/config/config-five';
import JITHTTPCLIENT from './CA/JITHTTPCLIENT';

const vm = new Vue();
/*
 * @description 获取地址栏参数
 */
export const getUrlParame = paramName => {
    const urlParams = {};
    const url = window.location.href;
    const idx = url.indexOf('?');
    if (idx === -1) {
        return false;
    }
    let params = url.substring(url.indexOf('?') + 1);
    params = params.split('#');
    params = params[0].split('&');
    params.forEach(item => {
        const param = item.split('=');
        urlParams[param[0]] = decodeURIComponent(param[1]);
    });
    if (paramName) {
        return urlParams[paramName];
    }
    return urlParams;
};

/*
 * @description 获取地址栏hash参数
 */
export const getUrlHash = paramName => {
    const urlParams = {};
    const url = window.location.href;
    const idx = url.indexOf('#');
    if (idx === -1) {
        return false;
    }
    let params = url.substring(url.indexOf('#') + 1);
    params = params.split('&');
    params.forEach(item => {
        const param = item.split('=');
        urlParams[param[0]] = decodeURIComponent(param[1]);
    });
    if (paramName) {
        return urlParams[paramName];
    }
    return urlParams;
};

/*
 * 判断浏览器类型是否为IE
 */
export const isIE = () => {
    const u = window.userAgent || navigator.userAgent;
    return u.indexOf('MSIE') > 0 || u.indexOf('Trident') > 0;
};

/*
 * 判断对象是否为空
 */
export const isEmptyObj = obj => (
    !obj || Object.keys(obj).length === 0
);

/**
 * 判断目标对象是否含有指定字段名
 * @param {Object} obj 目标对象
 * @param {String} field 字段名
 */
export const hasField = (obj, field) => {
    if (!obj || isEmptyObj(obj)) return false;
    const keyList = Object.keys(obj).map(key => key);
    return keyList.includes(field);
};

/**
 * 日期格式化
 * (new Date(), 'yyyy-mm-dd hh:ii:ss.S')==> 2006-07-02 08:09:04.423
 * (new Date(), 'yyyy-mm-dd E HH:ii:ss') ==> 2009-03-10 二 20:09:04
 * (new Date(), 'yyyy-mm-dd EE hh:ii:ss') ==> 2009-03-10 周二 08:09:04
 * (new Date(), 'yyyy-mm-dd EEE hh:ii:ss') ==> 2009-03-10 星期二 08:09:04
 * (new Date(), 'yyyy-m-d h:m:s.S') ==> 2006-7-2 8:9:4.18
 * (new Date(), 'yyyy/mm/dd hh:ii:ss.S') ==> 2017/04/17 10:00:48.282
 */
export const formatDate = (value, fmt) => {
    if (!value) {
        return null;
    }
    // ie
    let newFmt = fmt;
    let date = value;
    if (String(date).match('-')) {
        date = date.replace('-', '/');
    }
    /* eslint-disable no-param-reassign */
    date = new Date(date);
    /* eslint no-console: 'off' */
    if (date.toString() === 'Invalid Date') {
        console.log('日期不合法');
    }
    const o = {
        'M+': date.getMonth() + 1, // 月份,
        'm+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours() % 24 === 0 ? 0 : date.getHours() % 24, // 小时
        'H+': date.getHours(), // 小时
        'i+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds() // 毫秒
    };
    const week = {
        0: '/u65e5',
        1: '/u4e00',
        2: '/u4e8c',
        3: '/u4e09',
        4: '/u56db',
        5: '/u4e94',
        6: '/u516d'
    };
    if (/(y+)/.test(newFmt)) {
        newFmt = newFmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    if (/(E+)/.test(newFmt)) {
        newFmt = newFmt.replace(RegExp.$1, (match, p1) => {
            if (p1.length > 1) {
                return (p1.length > 2 ? '/u661f/u671f' : '/u5468') + week[`${date.getDay()}`];
            }
            return week[`${date.getDay()}`];
        });
    }
    const keys = Object.keys(o);
    for (let k = 0, len = keys.length; k < len; k += 1) {
        const prop = keys[k];
        if (new RegExp(`(${prop})`).test(newFmt)) {
            newFmt = newFmt.replace(RegExp.$1, (RegExp.$1.length === 1) ?
                (o[prop]) : ((`00${o[prop]}`).substr((`${o[prop]}`).length)));
        }
    }
    /* eslint-disable consistent-return */
    return newFmt;
};

/**
 * 判断是否为日期
 * @param {*} date 要判断是否为日期的值
 */
export const isDate = date => {
    if (date === null || date === undefined) return false;
    if (Number.isNaN(new Date(date).getTime())) return false;
    if (Array.isArray(date)) return false; // deal with `new Date([ new Date() ]) -> new Date()`
    return true;
};

/**
 * 获取指定时间前几个月或几年的时间戳
 * @param {timestamp} time 目标时间
 * @param {Object} obj 时间区间跨度
 * @param {String || Number} obj.range 时间区间
 * @param {String} obj.dimension 时间维度：年 || 月
 */
export const getHistoryRangeTimestamp = (time, obj = {}) => {
    if (!isDate(time)) return;
    let value = `${time}-01`; // 补齐日期，为兼容IE
    if (String(value).match('-')) {
        value = value.replace(/-/g, '/');
    }
    const { range = 1, dimension = 'month' } = obj;
    const count = dimension === 'month' ? parseInt((range / 12), 10) : range;
    const monthR = range % 12;
    const year = formatDate(value, 'yyyy') * 1;
    const month = new Date(value).getMonth() + 1;
    const monthT = month - monthR + 1;
    let yearF = year - count;
    let monthF = month;
    if (dimension === 'month') {
        yearF = monthT < 1 ? year - 1 : year;
        monthF = monthT < 1 ? monthT + 12 : monthT;
    }
    return new Date(`${yearF}/${monthF}/01`).getTime();
};

/**
 * 获取指定时间前几天或几个月或几年的时间
 * @param {timestamp} time 目标时间
 * @param {Object} obj 时间区间跨度
 * @param {String || Number} obj.range 时间区间
 * @param {String} obj.dimension 时间维度：year || month || day
 * @param {String} format 时间类型
 */
export const getHistoryRangeDate = (time, obj = {}, format = 'YYYY-MM') => {
    if (!isDate(time)) return;
    const { range = 1, dimension = 'year' } = obj;
    return dayjs().subtract(range, dimension).format(format);
};

/*
 * 下载文件
 */
export const downloadFile = url => {
    const iframeEle = document.createElement('iframe');
    iframeEle.src = url;
    iframeEle.style.display = 'none';
    document.body.appendChild(iframeEle);
};

/*
 * 深度遍历获取目标
 * param {Array} source 源数据
 * param {String} target 目标值
 */
export const findTargetByDeepTraverse = (source, target, result = []) => {
    const localSource = JSON.parse(JSON.stringify(source));
    localSource.forEach(item => {
        if (item.name.indexOf(target) !== -1) {
            result.push(item);
        } else if (item.children && item.children.length) {
            findTargetByDeepTraverse(item.children, target, result);
        }
    });
    return result;
};

/*
 * 区分监管端项目内耗材和药品
 * param {String} path 页面路径
 */
export const findProjectType = path => {
    const drugReg = /\/drug\//;
    const conReg = /\/consumable\//;
    let urlStr = '';
    if (drugReg.test(path)) {
        urlStr = 'drug';
    }
    if (conReg.test(path)) {
        urlStr = 'consumable';
    }
    return urlStr;
};

/**
 * 倒计时
 * @param {String} target 目标时间
 */
export const countDown = target => {
    const oDate = new Date();// 获取日期对象
    const oldTime = oDate.getTime();// 现在距离1970年的毫秒数
    const newDate = new Date(isIE() && String(target).match('-') ? target.replace('-', '/') : target);
    const newTime = newDate.getTime();// 2019年距离1970年的毫秒数
    if (newTime >= oldTime) {
        let second = Math.floor((newTime - oldTime) / 1000);// 未来时间距离现在的秒数
        const day = Math.floor(second / 86400);// 整数部分代表的是天；一天有24*60*60=86400秒 ；
        second %= 86400;// 余数代表剩下的秒数；
        const hour = Math.floor(second / 3600);// 整数部分代表小时；
        second %= 3600; // 余数代表 剩下的秒数；
        const minute = Math.floor(second / 60);
        second %= 60;
        return {
            day,
            hour,
            minute,
            second
        };
    }
    return false;
};

/*
 * @description 密码加密
 */
export const encryptPass = word => {
    const encrypted = CryptoJS.SHA256(word);
    return encrypted.toString();
};

/*
 * param {String} word 待加密的字符串
 * param {String} keyStr aes加密需要用到的16位字符串的key
 */
export const encrypt = (word, keyStr) => {
    const defKey = keyStr || 'Lic6J8807nW5K674';
    const key = CryptoJS.enc.Utf8.parse(defKey);
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(
        srcs,
        key,
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    return encrypted.toString();
};

/*
 * param {String} word 待解密的字符串
 * param {String} keyStr aes解密需要用到的16位字符串的key
 */
export const decrypt = (word, keyStr) => {
    const defKey = keyStr || 'Lic6J8807nW5K674';
    const key = CryptoJS.enc.Utf8.parse(defKey);
    const decrypted = CryptoJS.AES.decrypt(
        word,
        key,
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    return CryptoJS.enc.Utf8.stringify(decrypted).toString();
};

/**
 * 对象深度拷贝
 * @param {Object} obj 拷贝对象
 */
export const deepCopy = obj => (oldObj => {
    // 定义一个新的空对象
    let newObject = {};
    // 判断原对象是否存在
    if (oldObj) {
        // 判断原对象的类型
        if (oldObj.constructor === Object) {
            newObject = new oldObj.constructor();
        } else {
            newObject = new oldObj.constructor(oldObj.valueOf());
        }
        // 遍历克隆原对象的属性
        Object.keys(oldObj).forEach(key => {
            if (newObject[key] !== oldObj[key]) {
                if (typeof (oldObj[key]) === 'object') {
                    // 对象内部的子对象
                    newObject[key] = deepCopy(oldObj[key]);
                } else {
                    newObject[key] = oldObj[key];
                }
            }
        });
        // 克隆原对象的常用方法
        // newObject.toString = oldObj.toString;
        // newObject.valueOf = oldObj.valueOf;
        return newObject;
    }
})(obj);

/**
 * 对象深度合并
 * @param {Object} initObj 原始对象
 * @param {Object} targetObj 合并对象
 */
export const deepMergeObj = (initObj, targetObj) => {
    const resultObj = initObj;
    const keys = Object.keys(targetObj);
    keys.forEach(key => {
        resultObj[key] = initObj[key] && initObj[key].toString() === '[object Object]' ? deepMergeObj(initObj[key], targetObj[key]) : targetObj[key];
    });
    return resultObj;
};

/**
 * 数据转换
 * @param {String} convert 转换数字
 */
export const dataConvert = convert => {
    let defConvert = convert || 0;
    if (typeof defConvert === 'string') {
        defConvert = parseFloat(defConvert);
    }
    if (defConvert >= 10000 && defConvert < 10000 * 10000) {
        defConvert /= 10000;
        defConvert = `${defConvert.toFixed(2)}万`;
    } else if (defConvert >= 10000 * 10000) {
        defConvert /= (10000 * 10000);
        defConvert = `${defConvert.toFixed(2)}亿`;
    }
    return defConvert;
};
/**
 * 根据不同环境，处理请求头
 */
export const headerConfig = url => {
    let headerObj;
    let authorization;
    const mode = process.env.MODE;
    const authorizations = ['/auth/oauth/token', '/upms/user/caCheckUser', '/auth/mobile/token/social'];
    const jwtData = localStorage.getItem('token');
    if (mode === 'first' || mode === 'five') {
        // 对接五包登录
        const refreshToken = localStorage.getItem('refreshToken');
        const accountType = localStorage.getItem('accountType');
        headerObj = {
            Authorization: jwtData || '',
            refreshToken: refreshToken || '',
            accountType: accountType || '',
            'X-XSRF-TOKEN': vm.$cookies.get('XSRF-TOKEN')
        };
    } else if (mode === 'upms') {
        // 对接upms登录
        if (authorizations.indexOf(url) !== -1) {
            authorization = 'Basic aHhtZWM6aHhtZWM=';
        } else {
            authorization = `Bearer ${jwtData}`;
        }
        headerObj = {
            Authorization: authorization || ''
        };
    } else if (mode === 'local') {
        // 走我们自己包登录
        headerObj = {
            Authorization: jwtData || '',
            'X-XSRF-TOKEN': vm.$cookies.get('XSRF-TOKEN')
        };
    }
    return headerObj;
};
/*
 *  处理文件流
 *  @param {string}  url  请求url
 *  @param {object} param  请求传参
 *  @param {string} fileName  文件名称
 *  @param {boolean} notFull 请求地址是否是全路径标志
 *  全路径是文件服务地址，不需要token和baseUrl；不是全路径，本地不需要baseUrl，测试环境需要，同时需要token
 */
export const DownLoader = (url, param, fileName, notFull) => {
    const headers = headerConfig();
    const notFullPath = notFull || false;
    Axios({
        method: 'get',
        url: notFullPath ? `${ConfigLocal.baseUrl || ConfigFirst.baseUrl || ConfigFive.baseUrl || ''}${url}` : url,
        data: param,
        headers: notFullPath ? headers : '',
        responseType: 'blob'
    }).then(res => {
        const { data } = res;
        if (data.type !== 'application/json') {
            const blob = new Blob([data]);
            // 非IE下载
            if ('download' in document.createElement('a')) {
                const elink = document.createElement('a');
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }
            // IE、edge
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            }
        } else {
            // 通过FileReader来获取异常result
            const reader = new FileReader();
            reader.readAsText(data, 'utf-8');
            reader.onload = e => {
                const msg = JSON.parse(e.target.result);
                if (Object.prototype.hasOwnProperty.call(msg, 'code')) {
                    vm.$message.error(msg.detail);
                    return false;
                }
            };
        }
    }).catch(err => {
        console.log(err);
        vm.$message.error('未查到文件');
    });
};

/**
 * 深度拷贝
 */
export const cloneCopy = obj => {
    if (obj == null) { return null; }
    const result = Array.isArray(obj) ? [] : {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object') {
                result[key] = cloneCopy(obj[key]); // 如果是对象，再次挪用该要领本身
            } else {
                result[key] = obj[key];
            }
        }
    }
    return result;
};

/**
 * 集成门户跳转菜单统一方法
 */
export const skipPage = param => {
    if (!param) return;
    if (param.constructor === Object) {
        const { menuId, urlParam } = param;
        portalMessageSend.openMenu({
            // 一级菜单编号
            firstMenuId: 'tps-local',
            // 菜单编号
            menuId,
            // url参数
            urlParam
        });
    }
};

/**
 * 集成门户隐藏菜单方法
 */
export const hideMenu = param => {
    if (!param) return;
    if (param.constructor === Object) {
        const { menuIds } = param;
        portalMessageSend.message({
            action: 'hideMenusById',
            data: {
                firstMenuId: 'tps-local',
                menuIds
            }
        });
    }
};


/**
 * 集成门户统一设置参数方法
 */
export const setParam = param => {
    if (!param) return;
    if (param.constructor === Object) {
        const { menuIds, urlParam } = param;
        // 预置项目内菜单参数
        portalMessageSend.message({
            action: 'batchSetPresetParams',
            data: {
                menuIds,
                firstMenuId: 'tps-local',
                urlParam
            }
        });
    }
};

/**
 * 关闭浏览器当前窗口，只适用于window.open()打开的页签
 */
export const closeWebPage = () => {
    if (navigator.userAgent.indexOf('MSIE') > 0) {
        if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
            window.opener = null;
            window.close();
        } else {
            window.open('', '_top');
            window.top.close();
        }
    } else if (navigator.userAgent.indexOf('Firefox') > 0) {
        window.location.href = 'about:blank ';
        window.close();
    } else {
        window.opener = null;
        window.open('', '_self', '');
        window.close();
    }
};

/**
 * 加密方法
 * @param {String} dSign 加密内容
 * @param {String} pubKey 加密公钥，非必须参数
 */
// CA 加解密方法，初始化参数
const initParam = '<?xml version=\\"1.0\\" encoding=\\"utf-8\\"?>' +
        '<authinfo><liblist>' +
        '<lib type=\\"CSP\\" version=\\"1.0\\" dllname=\\"\\" ><algid val=\\"SHA1\\" sm2_hashalg=\\"sm3\\"/></lib>' +
        '<lib type=\\"SKF\\" version=\\"1.1\\" dllname=\\"R0FLRVlfU0tGLmRsbA==\\" ><algid val=\\"SHA1\\" sm2_hashalg=\\"sm3\\"/></lib>' +
        '<lib type=\\"SKF\\" version=\\"1.1\\" dllname=\\"bXRva2VuX2dtMzAwMF9KSVQuZGxs\\" ><algid val=\\"SHA1\\" sm2_hashalg=\\"sm3\\"/></lib>' +
        '<lib type=\\"SKF\\" version=\\"1.1\\" dllname=\\"SERfR01DQUlTLmRsbA==\\" ><algid val=\\"SHA1\\" sm2_hashalg=\\"sm3\\"/></lib>' +
        '<lib type=\\"SKF\\" version=\\"1.1\\" dllname=\\"U2h1dHRsZUNzcDExXzMwMDBHTS5kbGw=\\" ><algid val=\\"SHA1\\" sm2_hashalg=\\"sm3\\"/></lib>' +
        '<lib type=\\"SKF\\" version=\\"1.1\\" dllname=\\"SklUR01LRVlfU0pLMTQyNC5kbGw=\\" ><algid val=\\"SHA1\\" sm2_hashalg=\\"sm3\\"/></lib>' +
        '<lib type=\\"SKF\\" version=\\"1.1\\" dllname=\\"bXRva2VuX2dtMzAwMC5kbGw=\\" ><algid val=\\"SHA1\\" sm2_hashalg=\\"sm3\\"/></lib>' +
        '</liblist></authinfo>';
const initIEParams = '<?xml version="1.0" encoding="utf-8"?>' +
        '<authinfo><liblist>' +
        '<lib type="CSP" version="1.0" dllname="" ><algid val="SHA1" sm2_hashalg="sm3"/></lib>' +
        '<lib type="SKF" version="1.1" dllname="R0FLRVlfU0tGLmRsbA==" ><algid val="SHA1" sm2_hashalg="sm3"/></lib>' +
        '<lib type="SKF" version="1.1" dllname="bXRva2VuX2dtMzAwMF9KSVQuZGxs" ><algid val="SHA1" sm2_hashalg="sm3"/></lib>' +
        '<lib type="SKF" version="1.1" dllname="SERfR01DQUlTLmRsbA==" ><algid val="SHA1" sm2_hashalg="sm3"/></lib>' +
        '<lib type="SKF" version="1.1" dllname="U2h1dHRsZUNzcDExXzMwMDBHTS5kbGw=" ><algid val="SHA1" sm2_hashalg="sm3"/></lib>' +
        '<lib type="SKF" version="1.1" dllname="SklUR01LRVlfU0pLMTQyNC5kbGw=" ><algid val="SHA1" sm2_hashalg="sm3"/></lib>' +
        '<lib type="SKF" version="1.1" dllname="bXRva2VuX2dtMzAwMC5kbGw=" ><algid val="SHA1" sm2_hashalg="sm3"/></lib>' +
        '</liblist></authinfo>';
export const doEmailDSign = (dSign, pubKey) => {
    const keyBase64 = pubKey || '';
    let ret = -1;
    let message = '';

    if (dSign === '') {
        vm.$message.error('加密内容不能为空!');
        return;
    }
    ret = JITHTTPCLIENT.RegisterParam(initParam, initIEParams);
    if (ret !== 0) {
        if (ret === 100) {
            vm.$message.error('数据格式错误');
        } else if (ret === 500) {
            vm.$message.error('业务处理中');
        } else {
            ret = JITHTTPCLIENT.GetErrorCode();
            if (ret !== 0) {
                message = JITHTTPCLIENT.GetErrorMessage(ret);
                vm.$message.error('初始化失败，如未插入Ukey，请插入');
            }
        }
        return;
    }
    // 设置证书选择方式，1 只有一个证书时不需手动选择； 0 只有一个证书时也需手动选择
    ret = JITHTTPCLIENT.SetCertChooseType(1);
    if (ret !== 0) {
        ret = JITHTTPCLIENT.GetErrorCode();
        if (ret !== 0) {
            message = JITHTTPCLIENT.GetErrorMessage(ret);
            vm.$message.error(`设置证书选择类型失败，错误码：${ret} 错误信息：${message}`);
        }
        return;
    }
    // 设置签名、加密、解密或验签名证书
    // 加密证书颁发者主题(只展示相关ca证书)
    // const type = 'CN=Certificate Authority Of NHSA SM2, O=NHSA, C=CN';
    // const certTheme = JITHTTPCLIENT.GetCertInfo('EC', '', '');
    // console.log('certTheme', certTheme);
    ret = JITHTTPCLIENT.SetCert2('EC', '', '', '', '', keyBase64, '2');
    if (ret !== 0) {
        ret = JITHTTPCLIENT.GetErrorCode();
        if (ret !== 0 && ret !== 3758096386) {
            message = JITHTTPCLIENT.GetErrorMessage(ret);
            vm.$message.error(`设置证书过滤失败，错误码：${ret} 错误信息：${message}`);
        }
        return;
    }
    // 加密
    message = JITHTTPCLIENT.EncryptEnvelopStr('', dSign);
    ret = JITHTTPCLIENT.GetErrorCode();
    if (ret !== 0) {
        message = JITHTTPCLIENT.GetErrorMessage(ret);
        vm.$message.error(`加密数字信封失败，错误码：${ret} 错误信息：${message}`);
        return;
    }
    return message;
};

/**
 * 解密方法
 * @param {String} vDsign 待解密内容
 */

export const doValidataDSign = vDsign => {
    if (vDsign === '') {
        vm.$message.error('解密内容不能为空！');
        return;
    }
    let ret = -1;
    let message = '';

    ret = JITHTTPCLIENT.RegisterParam(initParam, initIEParams);
    if (ret !== 0) {
        if (ret === 100) {
            vm.$message.error('数据格式错误');
        } else if (ret === 500) {
            vm.$message.error('业务处理中，请稍等');
        } else {
            ret = JITHTTPCLIENT.GetErrorCode();
            if (ret !== 0) {
                message = JITHTTPCLIENT.GetErrorMessage(ret);
                vm.$message.error('初始化失败，如未插入Ukey，请插入');
            }
        }
        return;
    }
    // 解密
    ret = JITHTTPCLIENT.DecryptEnvelop(vDsign);
    if (ret !== 0) {
        ret = JITHTTPCLIENT.GetErrorCode();
        if (ret !== 0 && ret !== 3758096386) {
            message = JITHTTPCLIENT.GetErrorMessage(ret);
            if (ret === 3758096514) {
                vm.$message.error('未发现有效的Ukey 设备');
            } else {
                vm.$message.error(message);
            }
        }
        return;
    }
    message = JITHTTPCLIENT.GetData();
    return message;
};

/**
 * 截取指定字符后的内容
 * @param path 截取内容
 * @param str 字符
 */
export const getCaption = (path, str) => {
    const index = path.lastIndexOf(str);
    return path.substring(index + 1, path.length);
};

/**
 * 上传附件校验
 * @param {Array} typeArr 接收上传文件的类型
 * @param {Object} file 文件数据
 * @param {String} msg 错误文案提示
 */
export const limitsFile = (file, typeArr, msg) => {
    const { name } = file;
    const fileType = getCaption(name, '.');
    if (typeArr.includes(fileType)) {
        return true;
    }
    vm.$message.error(msg);
    return false;
};

/*
 * param {String} word 待加密的字符串
 * param {String} keyStr aes加密需要用到的16位字符串的key
 */
export const encrypt2 = data2 => {
    let key = '2dskjk3j42hjkdsh';
    let result = '';
    key = CryptoJS.enc.Latin1.parse(key);
    const iv = key;
    // 加密
    const encrypted = CryptoJS.AES.encrypt(
        data2,
        key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        }
    );
    result = encrypted.toString();
    return result;
};

/*
* @description 高度根据屏幕比例进行换算
*/
export const heightConvert = val => {
    if (!val) return val;
    const height = getUrlParame('spc') === '1' ? 640 : document.documentElement.clientHeight;
    return (val * height) / 756;
};

/*
* @description 接口轮训,支持传多个请求方法
*/
export const interval = (params, s = 900) => {
    if (!params) return;
    let timeId;
    const currentS = s * 1000;
    if (Array.isArray(params)) {
        timeId = [];
        params.forEach(item => {
            if (typeof item === 'function') {
                item();
                const itemId = setInterval(() => {
                    item();
                }, currentS);
                timeId.push(itemId);
            }
        });
    } else if (typeof params === 'function') {
        params();
        timeId = setInterval(() => {
            params();
        }, currentS);
    }
    return timeId;
};

/*
 * @description 保留小数点位数
 */
export const toFixed2 = val => {
    if (!val) return '0.00';
    return Number(val).toFixed(2);
};

/*
 * @description 保留小数点位数
 */
export const noToFixed = val => {
    if (!val) return '0';
    return Number(val).toFixed(0);
};

/*
 * @description 保留小数点位数
 */
export const toFixedNumber2 = val => {
    if (!val) return '0';
    const num = Number(val).toFixed(2);

    return Number(num);
};

/**
 * 通过文件Id获取文件url
 * @param {Number} fileId 文件id
 */
export const getFileUrl = fileId => new Promise((resolve, reject) => {
    if (!fileId) {
        window.App.$message.error('没有文件');
        return;
    }
    window.App.$fetch({
        url: window.App.$bkConfig.filePath,
        method: 'get',
        params: {
            id: fileId
        }
    }).then(res => {
        if (res.code !== 0) {
            reject(res.msg);
        } else {
            resolve(res.data.url, res.data.filename);
        }
    });
});


/*
 *  判断药品+耗材tab展示
 *  drug为1且msc为1或extFieldObj为空，展示药品+耗材
 *  drug为1且msc为0展示药品
 *  drug为0且msc为1展示耗材
 */
export const tabDisplay = () => {
    const extFieldObj = localStorage.getItem('extFieldStr') ?
        JSON.parse(localStorage.getItem('extFieldStr')) :
        null;
    let flag;
    if (!extFieldObj || (extFieldObj.drug === 1 && extFieldObj.mcs === 1)) {
        flag = 3;
    } else if (extFieldObj.drug === 1 && extFieldObj.mcs === 0) {
        flag = 1;
    } else if (extFieldObj.drug === 0 && extFieldObj.mcs === 1) {
        flag = 2;
    }
    return flag;
};
