export default {
    userInfo: {},
    applyMeunList: [],
    pageTagsData: [],
    breadData: [],
    isImplant: '',
    regionList: [],
    // 带量采购项目
    quantityList: [],
    consumablesList: [],
    consumablesArea: []
};