/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-02-20 15:14:01
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-08-21 14:49:53
 * @Description: 设置同药理可替代品种
 * @Route: Route
 */
const List = () => import(/* webpackChunkName: "stock-mgr-list" */ '@/pages/mgr/procurement-project/rule-setting/replace-varieties/list');
const Detail = () => import(/* webpackChunkName: "stock-mgr-list" */ '@/pages/mgr/procurement-project/rule-setting/replace-varieties/detail');

export default [
    {
        path: 'replace-varieties/list',
        component: List,
        meta: {
            title: '设置同药理可替代品种'
        }
    },
    {
        path: 'replace-varieties/list/detail',
        component: Detail,
        meta: {
            title: '修改同药理可替代品种'
        }
    }
];