import Fetch from '@/utils/fetch';


import config from '@/config/config.base';

// 文件下载公共接口
export const apiDownload = '/web/comp/file/download/compress';

// 菜单 new
export const queryMenu = params => (
    Fetch({
        url: '/web/auth/role/query_user_menu',
        params
    })
);

// upms菜单
export const queryUpmsMenu = params => (
    Fetch({
        url: '/upms/menu',
        params
    })
);

// CA证书登录
export const CALogin = params => (
    Fetch({
        url: '/api/portal/user/calogin',
        method: 'post',
        params
    })
);


// 校验手机号是否存在
export const checkMobile = () => (
    Fetch({
        url: '/api/checkMobile'
    })
);

// 验证验证码 new
export const queryCheckCode = params => (
    Fetch({
        url: '/api/comp/vcode/image/verify',
        params
    })
);

// 获取用户信息 new
export const getUserInfo = () => (
    Fetch({
        url: '/web/auth/user/query_user_info'
    })
);

// 获取upms用户信息 new
export const getUpmsUserInfo = () => (
    Fetch({
        url: '/upms/user/getInfo',
        params: {
            id: localStorage.getItem('userKey')
        },
        method: 'get'
    })
);

// 登出 new
export const userLoginout = params => (
    Fetch({
        url: '/web/auth/user/logout',
        params
    })
);

// 获取项目菜单 new
export const getProjectMenu = params => (
    Fetch({
        url: '/web/auth/item/menu/query_item_menu',
        method: 'post',
        params
    })
);


// 获取随机数
export const getRandom = () => (
    Fetch({
        url: '/api/portal/user/readyca'
    })
);
// 获取CA相关信息
export const getCaInfo = params => (
    Fetch({
        url: '/api/portal/user/getcainfo',
        method: 'post',
        params
    })
);
// 绑定用户CA证书
export const bindUserCA = params => (
    Fetch({
        url: '/api/portal/user/bindca',
        method: 'post',
        params
    })
);
// 解绑用户CA证书
export const unBindUserCA = params => (
    Fetch({
        url: '/api/portal/user/unbindca',
        method: 'post',
        params
    })
);

// 获取部门列表
export const getDepartmentList = params => (
    Fetch({
        url: '/api/portal/department/pagelist',
        method: 'post',
        params
    })
);

// 删除部门
export const deleteDepartment = params => (
    Fetch({
        url: '/api/portal/department/deletedbydeptid',
        params
    })
);

// 根据文件id获取文件url
export const getFileUrl = params => (
    Fetch({
        url: config.filePath,
        params
    })
);

// 一包对接，请求隐藏展示菜单id
export const getMenuId = params => (
    Fetch({
        url: '/web/auth/item/menu/query_item_menu_ips',
        params
    })
);

// 一包对接，项目内菜单id
export const getProInnerMenuId = params => (
    Fetch({
        url: '/web/auth/item/menu/query_itemInside_menu_ips',
        params
    })
);

// portal
export const getPortalList = params => (
    Fetch({
        url: '/upms/sysSystem/getList',
        method: 'get',
        params
    })
);
