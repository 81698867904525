/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-07-26 14:57:21
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-10-16 15:29:43
 * @Description: 违规处罚监管/违规处罚/违规处罚处理
 */

const List = () => import(/* webpackChunkName: "list" */ '@/pages/mgr/project-analysis/illegal-punish/customized-solutions/list');

export default [
    {
        path: 'customized-solutions/list',
        component: List,
        meta: {
            title: '违规处罚类型管理'
        }
    }
];
