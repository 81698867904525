/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-10-31 15:54:46
 * @Last Modified by: maozg
 * @Last Modified time: 2020-03-03 18:07:52
 * @Description: 采购订单管理模块
 */
import View from '@/components/view';

const List = () => import('@/pages/trade/order-manage/consumable/order-list.vue');
const AdditionList = () => import('@/pages/trade/order-manage/consumable/addition-list.vue');
const OrderDetail = () => import('@/pages/trade/order-manage/consumable/order-details.vue');
const PlanDetail = () => import('@/pages/trade/order-manage/consumable/plan-details.vue');
const OrderTracking = () => import('@/pages/trade/order-manage/consumable/order-tracking.vue');


export default [
    {
        path: 'consumable/order-manage',
        component: View,
        meta: {
            title: '采购订单管理'
        },
        children: [
            {
                path: 'order-list',
                component: List,
                meta: {
                    title: '采购订单管理'
                }
            },
            {
                path: 'order-list/detail',
                component: OrderDetail,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'order-list/plan-detail',
                component: PlanDetail,
                meta: {
                    title: '采购计划明细',
                    display: 'none'
                }
            },
            {
                path: 'addition-list',
                component: AdditionList,
                meta: {
                    title: '补录订单管理'
                }
            },
            {
                path: 'addition-list/detail',
                component: OrderDetail,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'addition-list/plan-detail',
                component: PlanDetail,
                meta: {
                    title: '采购计划明细',
                    display: 'none'
                }
            },
            {
                path: 'order-tracking',
                component: OrderTracking,
                meta: {
                    title: '订单明细追踪'

                }
            }
        ]
    }
];
