/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-08-22 15:33:47
 * @Last Modified by: maozg
 * @Last Modified time: 2019-09-05 14:08:55
 * @Description: 业务监管/监测预警/资质有效期预警
 */

const List = () => import(/* webpackChunkName: "intelli-validity-list" */ '@/pages/mgr/business/monitor-warning/intelli-validity/list');
const Detail = () => import(/* webpackChunkName: "intelli-validity-list" */ '@/pages/mgr/business/monitor-warning/intelli-validity/detail');

export default [
    {
        path: 'intelli-validity/list',
        component: List,
        meta: {
            title: '资质有效期预警'
        }
    },
    {
        path: 'intelli-validity/detail',
        component: Detail,
        meta: {
            title: '企业资质详情',
            display: 'none'
        }
    }
];
