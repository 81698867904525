/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-12-15 11:32:08
 * @Last Modified by: xujh
 * @Last Modified time: 2020-12-17 11:22:03
 * @Description: Description
 * @Route: Route
 */


const settlementApply = () => import('@/pages/trade/hospital-pay/settlement-manage/drug/settlement-apply');
const ApplyDetail = () => import('@/pages/trade/hospital-pay/settlement-manage/drug/apply-detail');

const settlementAudit = () => import('@/pages/trade/hospital-pay/settlement-manage/drug/settlement-audit');
const settlementAuditSub = () => import('@/pages/trade/hospital-pay/settlement-manage/drug/settlement-audit-sub');
const AuditDetail = () => import('@/pages/trade/hospital-pay/settlement-manage/drug/audit-detail');


const routesDispath = [
    {
        path: 'apply-list',
        component: settlementApply,
        meta: {
            title: '结算申请管理'
        }
    },
    {
        path: 'apply-list/detail',
        component: ApplyDetail,
        meta: {
            display: 'none',
            title: '查看'

        }
    }
];
const routesMedical = [
    {
        path: 'audit-list',
        component: settlementAudit,
        meta: {
            title: '结算申请管理'
        }
    },
    {
        path: 'audit-list/detail',
        component: AuditDetail,
        meta: {
            display: 'none',
            title: '查看'
        }
    },
    {
        path: 'audit-sub-list',
        component: settlementAuditSub,
        meta: {
            title: '医联体结算申请管理'
        }
    },
    {
        path: 'audit-sub-list/detail',
        component: AuditDetail,
        meta: {
            display: 'none',
            title: '查看'
        }
    }
];
export {
    routesDispath,
    routesMedical
};