/*
 * @Author: guoxy
 * @Email: guoxy1@guahao.com
 * @Date: 2020-10-06 17:59:59
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-10-06 18:05:04
 * @Description: 企业端-阳光挂网
 */

const drug = () => import(/* webpackChunkName: "modeList" */ '@/pages/tender/sunshine-hanging/product-org/drug/list');
const consumable = () => import(/* webpackChunkName: "modeList" */ '@/pages/tender/sunshine-hanging/product-org/consumable/list');
export default [
    {
        path: 'product-org/drug/list',
        component: drug,
        meta: {
            title: '阳光挂网'
        }
    },
    {
        path: 'product-org/consumable/list',
        component: consumable,
        meta: {
            title: '阳光挂网'
        }
    }
];