/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-11-18 19:19:30
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-18 19:21:17
 * @Description: 综合监管/价格对比分析
 */
const List = () => import(/* webpackChunkName: "price-comparison-analysis" */ '@/pages/mgr/project-analysis/price-comparison-analysis/list');

export default [
    {
        path: 'price-comparison-analysis/list',
        component: List,
        meta: {
            title: '价格对比分析'
        }
    }
];