/*
 * @Author: zhuangmw
 * @Email: zhuangmw@guahao.com
 * @Date: 2019-08-22 17:13:03
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-12-15 15:39:34
 * @Description: 专题分析
 */

import View from '@/components/view';
import priceComparisonAnalysis from './price-comparison-analysis';
import PerformanceScoreManage from './performance-score-manage';
import PerformanceScorePublicity from './performance-score-publicity';
import PerformanceScoreSet from './performance-score-set';
import DrugShortage from './drug-shortage';
import IllegalPunish from './illegal-punish';
import StructuralAnalysis from './structural-analysis';
import ProvincialDrugPrice from './provincial-drug-price';
import AgencyInformation from './agency-information';

export default [
    {
        path: 'project-analysis',
        component: View,
        meta: {
            title: '专题分析'
        },
        children: [
            ...priceComparisonAnalysis,
            ...PerformanceScoreManage,
            ...PerformanceScorePublicity,
            ...PerformanceScoreSet,
            ...StructuralAnalysis,
            ...DrugShortage,
            ...IllegalPunish,
            ...ProvincialDrugPrice,
            ...AgencyInformation
        ]
    }
];
