/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-11
 * @lastModifiedBy yanghy
 * @lastModifiedTime 18:14
 * @route
 * @description 企业申投诉
 */

const EntStatementList = () => import(/* webpackChunkName: "list" */ '@/pages/tender/enterprise-statement/list');
const EntStatementDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/tender/enterprise-statement/detail');
const EntStatementEdit = () => import(/* webpackChunkName: "edit" */ '@/pages/tender/enterprise-statement/edit');

export default [
    {
        path: 'enterprise-statement/list',
        component: EntStatementList,
        meta: {
            title: '企业申投诉'
        }
    },
    {
        path: 'enterprise-statement/list/detail',
        component: EntStatementDetail,
        meta: {
            title: '详情',
            display: 'none'
        }
    },
    {
        path: 'enterprise-statement/list/edit',
        component: EntStatementEdit,
        meta: {
            title: '编辑',
            display: 'none'
        }
    },
    {
        path: 'enterprise-statement/list/add',
        component: EntStatementEdit,
        meta: {
            title: '新增',
            display: 'none'
        }
    }
];
