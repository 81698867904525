/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-08-29 19:01:37
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-12-29 11:16:18
 * @Description: 专题分析/短缺药专题
 */

const drugAnalyze = () => import(/* webpackChunkName: "drug-analyze" */ '@/pages/mgr/project-analysis/drug-shortage/drug-analyze');
const drugRanking = () => import(/* webpackChunkName: "drug-ranking" */ '@/pages/mgr/project-analysis/drug-shortage/drug-ranking');

export default [
    {
        path: 'drug-analyze',
        component: drugAnalyze,
        meta: {
            title: '短缺药分析'
        }
    },
    {
        path: 'drug-analyze/drug-ranking',
        component: drugRanking,
        meta: {
            title: '全国短缺药数量排行榜',
            display: 'none'
        }
    }
];