
var content = require("!!../../node_modules/_css-loader@2.1.1@css-loader/dist/cjs.js!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/_postcss-loader@3.0.0@postcss-loader/src/index.js??ref--2-3!../../node_modules/_less-loader@5.0.0@less-loader/dist/cjs.js!../../node_modules/_style-resources-loader@1.4.1@style-resources-loader/lib/index.js??ref--2-5!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/index.js??vue-loader-options!./vw-header.vue?vue&type=style&index=0&id=6ab2328e&lang=less&scoped=true&");

if(typeof content === 'string') content = [[module.id, content, '']];

var transform;
var insertInto;



var options = {"hmr":true}

options.transform = transform
options.insertInto = undefined;

var update = require("!../../node_modules/_style-loader@0.23.1@style-loader/lib/addStyles.js")(content, options);

if(content.locals) module.exports = content.locals;

if(module.hot) {
	module.hot.accept("!!../../node_modules/_css-loader@2.1.1@css-loader/dist/cjs.js!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/_postcss-loader@3.0.0@postcss-loader/src/index.js??ref--2-3!../../node_modules/_less-loader@5.0.0@less-loader/dist/cjs.js!../../node_modules/_style-resources-loader@1.4.1@style-resources-loader/lib/index.js??ref--2-5!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/index.js??vue-loader-options!./vw-header.vue?vue&type=style&index=0&id=6ab2328e&lang=less&scoped=true&", function() {
		var newContent = require("!!../../node_modules/_css-loader@2.1.1@css-loader/dist/cjs.js!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/_postcss-loader@3.0.0@postcss-loader/src/index.js??ref--2-3!../../node_modules/_less-loader@5.0.0@less-loader/dist/cjs.js!../../node_modules/_style-resources-loader@1.4.1@style-resources-loader/lib/index.js??ref--2-5!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/index.js??vue-loader-options!./vw-header.vue?vue&type=style&index=0&id=6ab2328e&lang=less&scoped=true&");

		if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];

		var locals = (function(a, b) {
			var key, idx = 0;

			for(key in a) {
				if(!b || a[key] !== b[key]) return false;
				idx++;
			}

			for(key in b) idx--;

			return idx === 0;
		}(content.locals, newContent.locals));

		if(!locals) throw new Error('Aborting CSS HMR due to changed css-modules locals.');

		update(newContent);
	});

	module.hot.dispose(function() { update(); });
}