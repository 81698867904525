/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-11-13 14:27:06
 * @Last Modified by: qiuwt
 * @Last Modified time: 2021-02-03 16:27:13
 * @Description: 微前端入口
 */

import '@babel/polyfill';
import { setPublicPath } from 'systemjs-webpack-interop';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Element from 'element-ui';
import singleSpaVue from 'single-spa-vue';
import storeData from '@/store';
import fetch from '@/utils/fetch';
import configs from '@/config/config-upms';
import { getUrlParame } from '@/utils/utils';
import pathArr from '@/utils/router-data';
import Components from '@/components/index';
import VeLine from 'v-charts/lib/line.common';
import VeRing from 'v-charts/lib/ring.common';
import VeHistogram from 'v-charts/lib/histogram.common';
import VeBar from 'v-charts/lib/bar.common';
import configBase from '@/config/config.base';
import VueSwiper from 'vue-awesome-swiper';
import Viewer from '@heisea/viewer';
import '../../utils/rem';
 
function init({ publicPath = 'hsa', routes = {} }, callback) {
    Vue.config.productionTip = false;
    const env = process.env.NODE_ENV;
    Vue.config.silent = env !== 'development';
    let menuObj = {}; // 项目内菜单
    const router = new VueRouter({
        mode: 'history',
        routes
    });
    // viewer组件初始化
    Vue.use(Viewer, {
        props: {
            fileId: 'fileId',
            fileName: 'originalFileName',
            fileSuffix: 'fileExt',
            fileUrl: 'fileUrl',
            data: 'data'
        },
        http: {
            filePath: configBase.filePath
        }
    });

    Vue.use(VueRouter);
    Vue.use(Element);
    Vue.use(VueSwiper);
    Vue.use(Components);
    Vue.component(VeLine.name, VeLine);
    Vue.component(VeRing.name, VeRing);
    Vue.component(VeHistogram.name, VeHistogram);
    Vue.component(VeBar.name, VeBar);
    if (callback)callback(Vue, router);
    const store = env === 'development' ? storeData : storeData || window.Store;
    Vue.mixin({
        methods: {
            $fetch(options) {
                // 避免应用互相污染
                return this.$root.fetch(options);
            }
        }
    });
    // Vue.prototype.$bkConfig = configs;
    const appOptions = {
        // eslint-disable-next-line
        render: h => <div id="app" class="global-wrapper">
            <router-view></router-view>
        </div>,
        store,
        router,
        computed: {
            bkConfig: () => configs,
            upmsUserInfo: () => window.Store?.state?.userInfo
        },
        watch: {
            $route(val) {
                // 微前端 项目内外处理
                if (window.location.pathname.split('/')[1] === 'tender') {
                    this.loadPojectM(val);
                }
            },
            upmsUserInfo: {
                handler(val) {
                    if (!val) {
                        return;
                    }
                    // 将upms的orgType转换
                    const obj = Object.assign({}, val);
                    let orgType = 0;
                    switch (Number(obj.orgType)) {
                    case 1: {
                        orgType = 2;
                        break;
                    }
                    case 2: {
                        orgType = 3;
                        break;
                    }
                    case 3: {
                        orgType = 4;
                        break;
                    }  
                    default: {
                        break;
                    }
                    }
                    obj.orgType = orgType;
                    this.$store.commit('SET_USERINFO', obj);
                },
                deep: true
            }
        },
        mounted() {
            if (window.location.pathname.split('/')[1] === 'tender') {
                // 微前端 项目内外处理
                menuObj = {};
                this.loadPojectM();
            }
        },
        methods: {
            fetch: config => fetch(config),
            loadPojectM(val) {
                const pageArr = [
                    '/tender/tendering-manage/list/project-config',
                    '/tender/express-manage/product-org/list/operation',
                    '/tender/express-manage/regulator-org/list'
                ];
                // 微前端 项目内外处理
                if (window.Store && process.env.NODE_ENV !== 'development') {
                    const itemId = getUrlParame('tenditmId');
                    const menu = menuObj[itemId];
                    if (menu) {
                        window.Store.commit('GET_APPLY_MENU', menu);
                    } else if (itemId && !pageArr.includes(val.path)) {
                        this.$root.fetch({
                            url: '/web/auth/item/menu/query_item_upms_menu',
                            method: 'post',
                            params: {
                                tenditmId: itemId,
                                tenditmType: localStorage.getItem('system_id')
                            }
                        }).then(res => {
                            if (res) {
                                menuObj[itemId] = res.data;
                                window.Store.commit('GET_APPLY_MENU', res.data);
                            }
                        });
                    } else {
                        const { outer } = menuObj;
                        if (outer) {
                            window.Store.commit('GET_APPLY_MENU', outer);
                        } else {
                            this.$root.fetch({
                                url: '/web/auth/role/query_user_upms_menu',
                                params: {
                                    tenditmType: localStorage.getItem('system_id')
                                }
                            }).then(res => {
                                if (res) {
                                    menuObj.outer = res.data;
                                    // 清除菜单
                                    window.Store.commit('ClEAN_APPLY_MENU');
                                    window.Store.commit('GET_APPLY_MENU', res.data);
                                }
                            });
                        }
                    }
                }
            }
        }
    };
    // 区分项目内，项目外菜单
    router.beforeEach((to, from, next) => {
        const { path: toPath, query } = to;
        const { tenditmId, tenditmType, gCode } = query;
        const { tenditmId: fromItemId, tenditmType: fromItemType, gCode: fGcode } = from.query;
        const urlArr = toPath.split('/');
        const token = localStorage.getItem('token');
        if (gCode) {
            localStorage.setItem('gCode', gCode);
        }
        const loginPath = ['/login', '/portal', '/aup'];
        // 回到登录
        if (!token && to.path !== loginPath) {
            next({
                path: loginPath,
                query: {
                    path: to.fullPath
                }
            });
        }
        // 判断是否需要tenditmId
        let notRequireId = false;
        for (let i = 0; i < urlArr.length; i += 1) {
            if (pathArr.includes(urlArr[i]) || toPath === '/tender/tendering-manage/list') {
                notRequireId = true;
                break;
            }
        }
        // 执行跳转
        if (!notRequireId && !tenditmId && !tenditmType && fromItemId && loginPath.indexOf(toPath) === -1) {
            next({
                path: toPath,
                query: {
                    tenditmId: fromItemId,
                    tenditmType: fromItemType,
                    gCode: localStorage.getItem('gCode'),
                    ...to.query
                }
            });
        } else if (!gCode && fGcode && (!(to.path === '/login'))) {
            next({
                path: toPath,
                query: {
                    gCode: fGcode,
                    ...to.query
                }
            });
        } else {
            next();
        }
    });
    // 微前端
    let vueLifecycles = {};
     
    if (env === 'development') {
        // 手动挂载
        window.App = new Vue(appOptions).$mount('#app');
    } else {
        // 注册应用
        setPublicPath(publicPath);
        // 保存路由信息到全局
        if (window.Store) {
            window.Store.commit('SAVE_ROUTE_DATA', routes);
        }
        vueLifecycles = singleSpaVue({
            Vue,
            appOptions
        });
    }
    
    return vueLifecycles;
}
 
// 项目启动的钩子
export default init;
