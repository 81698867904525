import View from '@/components/view';

const SupplementManage = () => import(/* webpackChunkName: "supplement-manage" */ '@/pages/tender/declare-manage/supplement-manage'); // 企业资料增补管理
const SupplementConfig = () => import(/* webpackChunkName: "supplement-config" */ '@/pages/tender/declare-manage/supplement-config'); // 企业资料增补配置
const SupplementDeclare = () => import(/* webpackChunkName: "supplement-manage" */ '@/pages/tender/declare-manage/supplement-declare'); // 企业资料增补申报
const FieldConfig = () => import(/* webpackChunkName: "field-config" */ '@/pages/tender/declare-manage/field-config'); // 企业增补资料字段配置
const Detail = () => import(/* webpackChunkName: "supplement-detail" */ '@/pages/tender/declare-manage/detail'); // 企业增补资料详情

const routes = [
    {
        path: 'declare-manage',
        component: View,
        meta: {
            title: '申报管理'
        },
        children: [
            {
                path: 'supplement-manage',
                component: SupplementManage,
                meta: {
                    title: '企业资料增补管理'
                }
            },
            {
                path: 'supplement-manage/detail',
                component: Detail,
                meta: {
                    title: '查看企业详情',
                    display: 'none'
                }
            },
            {
                path: 'supplement-manage/audit',
                component: Detail,
                meta: {
                    title: '审核企业资料增补',
                    display: 'none'
                }
            },
            {
                path: 'supplement-config',
                component: SupplementConfig,
                meta: {
                    title: '企业资料增补配置'
                }
            },
            {
                path: 'supplement-config/field-config',
                component: FieldConfig,
                meta: {
                    title: '企业增补资料字段配置',
                    display: 'none'
                }
            },
            {
                path: 'supplement-declare',
                component: SupplementDeclare,
                meta: {
                    title: '企业资料增补申报'
                }
            }
        ]
    }
];

export default routes;
