/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 10:36
 * @route
 * @description 报量项目管理
*/

const List = () => import(/* webpackChunkName: "quantity-list" */ '@/pages/trade/quantity-manage/list');
const ProvinceList = () => import(/* webpackChunkName: "province-list" */ '@/pages/trade/quantity-manage/province-list');
const Fill = () => import(/* webpackChunkName: "quantity-fill" */ '@/pages/trade/quantity-manage/drug/fill');
const ConsumableFill = () => import(/* webpackChunkName: "consumable-quantity-fill" */ '@/pages/trade/quantity-manage/consumable/fill');

export default [
    {
        path: 'quantity-manage/list',
        component: List,
        meta: {
            title: '国家项目报量'
        }
    },
    {
        path: 'quantity-manage/province-list',
        component: ProvinceList,
        meta: {
            title: '省级项目报量管理'
        }
    },
    {
        path: 'quantity-manage/list/drug/fill',
        component: Fill,
        meta: {
            title: '采购数据填写',
            display: 'none'
        }
    },
    {
        path: 'quantity-manage/list/consumable/fill',
        component: ConsumableFill,
        meta: {
            title: '采购数据填写',
            display: 'none'
        }
    }
];
