/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-02-20 14:47:33
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-08-21 14:59:49
 * @Description: 数据分析规则设置
 * @Route: Route
 */

import View from '@/components/view';
// 设置非中选品种
import ChosenDrugManage from './chosen-drug-manage';
// 机构约定量配置
import OrgAppointConfig from './org-appoint-config';
// 设置同药理可替代品种
import ReplaceVarieties from './replace-varieties';

export default [
    {
        path: 'rule-setting',
        component: View,
        meta: {
            title: '数据分析规则设置'
        },
        children: [
            ...ChosenDrugManage,
            ...OrgAppointConfig,
            ...ReplaceVarieties
        ]
    }
];