/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-11-16 16:54:08
 * @Last Modified by: caoyp
 * @Last Modified time: 2020-12-08 17:42:59
 * @Description: 微前端：只包含业务系统页面，不包含左侧菜单和头部
 */
// 基础数据
import Bdc from './bdc';
// 招采
import Tender from './tender';
// 交易结算
import Trade from './trade';
// 监管
import Mgr from './mgr';
// 监管大屏
import standSupervision from './mgr/full-amount';

const routes = [
    ...Mgr,
    ...Bdc,
    ...Tender,
    ...Trade,
    {
        ...standSupervision[0]
    }
];

export default routes;
