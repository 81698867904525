/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2019-09-20 10:51:38
 * @Last Modified by: yanshuai
 * @Last Modified time: 2020-12-07 16:18:40
 * @Description:  install
 */
import Header from './vw-header';
import Card from './card-box';
import Li from './li';
import Rectangle from './rectangle';

import Progress from './progress';


const components = [
    Header,
    Card,
    Li,
    Rectangle,
    Progress
];

const install = Vue => {
    components.forEach(component => (
        Vue.component(component.name, component)
    ));
};

export default {
    install,
    Header
};
