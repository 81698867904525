import View from '@/components/view';

// 耗材-结算-结算方案配置-管理机构
const settlementConfiguration = () => import(/* webpackChunkName: "settlementConfiguration" */ '@/pages/trade/solution-configuration/consumable/settlement-configuration');
const productSettlementConfiguration = () => import(/* webpackChunkName: "productSettlementConfiguration" */ '@/pages/trade/solution-configuration/consumable/product-settlement-configuration');
const settlementProcess = () => import(/* webpackChunkName: "settlementProcess" */ '@/pages/trade/solution-configuration/consumable/settlement-process');
const medicalTreatmentAlliance = () => import(/* webpackChunkName: "medicalTreatmentAlliance" */ '@/pages/trade/solution-configuration/consumable/medical-treatment-alliance');

const routes = [
    {
        path: 'solution-configuration/consumable',
        component: View,
        meta: {
            title: '结算方案配置'
        },
        children: [
            {
                path: 'solution-configuration/settlement-configuration/list',
                component: settlementConfiguration,
                meta: {
                    title: '结算方式配置'
                }
            },
            {
                path: 'solution-configuration/settlement-configuration/list/product',
                component: productSettlementConfiguration,
                meta: {
                    title: '产品结算方式配置',
                    display: 'none'
                }
            },
            {
                path: 'solution-configuration/settlement-process',
                component: settlementProcess,
                meta: {
                    title: '结算流程配置'
                }
            },
            {
                path: 'solution-configuration/medical-treatment-alliance',
                component: medicalTreatmentAlliance,
                meta: {
                    title: '医联体设置'
                }
            }
        ]
    }
];

export default routes;