/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2020-06-29 13:57:20
 * @Last Modified by: caoyp
 * @Last Modified time: 2020-12-08 15:33:21
 * @Description: 综合监管大屏路由总和
 */

import View from '@/pages/mgr/view';

// 监管大屏
import standVisual from './visual';

export default [
    {
        path: '/mgr',
        component: View,
        children: [
            ...standVisual
        ]
    }
];