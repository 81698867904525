/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-10-31 15:54:46
 * @Last Modified by: maozg
 * @Last Modified time: 2020-03-03 18:13:12
 * @Description: 药品-结算申请管理模块
 */
import View from '@/components/view';

const settlementApply = () => import('@/pages/trade/settlement-manage/drug/settlement-apply');
const ApplyDetail = () => import('@/pages/trade/settlement-manage/drug/apply-detail');

const settlementAudit = () => import('@/pages/trade/settlement-manage/drug/settlement-audit');
const settlementAuditSub = () => import('@/pages/trade/settlement-manage/drug/settlement-audit-sub');
const AuditDetail = () => import('@/pages/trade/settlement-manage/drug/audit-detail');


export default [
    {
        path: 'drug/settlement-manage',
        component: View,
        meta: {
            title: '结算管理'
        },
        children: [
            {
                path: 'apply-list',
                component: settlementApply,
                meta: {
                    title: '结算申请管理'
                }
            },
            {
                path: 'apply-list/detail',
                component: ApplyDetail,
                meta: {
                    display: 'none',
                    title: '查看'

                }
            },
            {
                path: 'audit-list',
                component: settlementAudit,
                meta: {
                    title: '结算申请审核'
                }
            },
            {
                path: 'audit-list/detail',
                component: AuditDetail,
                meta: {
                    display: 'none',
                    title: '查看'
                }
            },
            {
                path: 'audit-sub-list',
                component: settlementAuditSub,
                meta: {
                    title: '结算申请审核'
                }
            },
            {
                path: 'audit-sub-list/detail',
                component: AuditDetail,
                meta: {
                    display: 'none',
                    title: '查看'
                }
            }
        ]
    }
];
