/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-10-31 15:54:46
 * @Last Modified by: maozg
 * @Last Modified time: 2020-03-03 18:08:24
 * @Description: 采购计划管理模块
 */
import View from '@/components/view';

const List = () => import('@/pages/trade/plan-manage/drug/list.vue');
const Detail = () => import('@/pages/trade/plan-manage/drug/detail.vue');
const Edit = () => import('@/pages/trade/plan-manage/drug/edit.vue');
const reviewList = () => import('@/pages/trade/plan-manage/drug/review-list');
const reviewListDetail = () => import('@/pages/trade/plan-manage/drug/review');
const sendShoppingList = () => import('@/pages/trade/plan-manage/drug/send');


export default [
    {
        path: 'drug/plan-manage',
        component: View,
        meta: {
            title: '采购计划管理'
        },
        children: [
            {
                path: 'plan-list',
                component: List,
                meta: {
                    title: '采购计划管理'
                }
            },
            {
                path: 'plan-list/detail',
                component: Detail,
                meta: {
                    title: '采购计划详情',
                    display: 'none'

                }
            },
            {
                path: 'plan-list/edit',
                component: Edit,
                meta: {
                    title: '编辑采购计划',
                    display: 'none'
                }
            },
            {
                path: 'audit-list',
                component: reviewList,
                meta: {
                    title: '采购计划审核'
                }
            },
            {
                path: 'audit-list/detail',
                component: Detail,
                meta: {
                    title: '采购计划详情',
                    display: 'none'

                }
            },
            {
                path: 'audit-list/review',
                component: reviewListDetail,
                meta: {
                    title: '审核采购计划',
                    display: 'none'

                }
            },
            {
                path: 'audit-list/send',
                component: sendShoppingList,
                meta: {
                    title: '发送采购计划',
                    display: 'none'
                }
            }
        ]
    }
];
