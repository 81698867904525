/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-11-15 14:42:15
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-08-21 15:34:12
 * @Description: 机构约定量配置模块路由
 */

const List = () => import(/* webpackChunkName: "org-appoint-config-list" */ '@/pages/mgr/procurement-project/rule-setting/org-appoint-config/list');
const Add = () => import(/* webpackChunkName: "org-appoint-config-add" */ '@/pages/mgr/procurement-project/rule-setting/org-appoint-config/add');
const Edit = () => import(/* webpackChunkName: "org-appoint-config-edit" */ '@/pages/mgr/procurement-project/rule-setting/org-appoint-config/edit');

export default [
    {
        path: 'org-appoint-config/list',
        component: List,
        meta: {
            title: '机构约定量配置'
        }
    },
    {
        path: 'org-appoint-config/list/add',
        component: Add,
        meta: {
            title: '新增品规设置'
        }
    },
    {
        path: 'org-appoint-config/list/edit',
        component: Edit,
        meta: {
            title: '修改品规设置'
        }
    }
];