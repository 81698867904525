/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-10-28 16:43:50
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-12-29 14:14:36
 * @Description: 违规处罚监管/违规处罚/产品黑名单日志
 */

const List = () => import(/* webpackChunkName: "product-blacklist-log-list" */ '@/pages/mgr/project-analysis/illegal-punish/product-blacklist-log/list');
const Read = () => import(/* webpackChunkName: "product-blacklist-log-read" */ '@/pages/mgr/project-analysis/illegal-punish/handle/read');

export default [
    {
        path: 'product-blacklist-log/list',
        component: List,
        meta: {
            title: '产品黑名单日志'
        }
    },
    {
        path: 'product-blacklist-log/list/read',
        component: Read,
        meta: {
            title: '查看详情',
            display: 'none'
        }
    }
];
