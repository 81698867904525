/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-02-20 14:34:31
 * @Last Modified by: yanshuai
 * @Last Modified time: 2020-10-15 10:55:52
 * @Description: 采购进度与结算分析
 * @Route: Route
 */

import View from '@/components/view';
// 4+7药品采购分析
import PurchaseSpecial from './purchase-special';
// 中选耗材采购进度
import SelectedConsumables from './selected';
// 医疗机构采购进度
import medicalProcurement from './medical-procurement';

export default [
    {
        path: 'com-purchase-analysis',
        component: View,
        meta: {
            title: '采购进度与结算分析'
        },
        children: [
            ...PurchaseSpecial,
            ...SelectedConsumables,
            ...medicalProcurement
        ]
    }
];