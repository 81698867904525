/*
 * @Author: shandan
 * @Email: shandan@guahao.com
 * @Date: 2020-03-04 15:58:43
 * @Last Modified by: caoyp
 * @Last Modified time: 2020-07-29 10:41:53
 * @Description: 耗材-采购数据统计
 */
const statisticsList = () => import(/* webpackChunkName: "statisticsList" */ '@/pages/tender/quantity-statistics/consumable/list'); 
const ConsumableFill = () => import(/* webpackChunkName: "consumable-quantity-fill" */ '@/pages/trade/quantity-manage/consumable/fill');

const routes = [
    {
        path: 'consumable/list',
        component: statisticsList,
        meta: {
            title: '采购数据汇总',
            flag: 'quantity'
        }
    },
    {
        path: 'consumable/list/fill',
        component: ConsumableFill,
        meta: {
            title: '采购数据填报',
            flag: 'quantity'
        }
    }
];

export default routes;