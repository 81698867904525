/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-11-15 14:42:15
 * @Last Modified by: panzw2
 * @Last Modified time: 2021-02-23 10:20:55
 * @Description: 设置非中选品种模块路由
 */

const List = () => import(/* webpackChunkName: "chosen-drug-manage-list" */ '@/pages/mgr/procurement-project/com-rule-setting/chosen-drug-manage/list');
const SetProduct = () => import(/* webpackChunkName: "chosen-drug-manage-set-product" */ '@/pages/mgr/procurement-project/com-rule-setting/chosen-drug-manage/set-product');
const Detail = () => import(/* webpackChunkName: "chosen-drug-manage-list" */ '@/pages/mgr/procurement-project/com-rule-setting/chosen-drug-manage/detail');

export default [
    {
        path: 'chosen-drug-manage/list',
        component: List,
        meta: {
            title: '设置中选耗材'
        }
    },
    {
        path: 'chosen-drug-manage/list/set-product',
        component: SetProduct,
        meta: {
            title: '中选产品维护 / 约定量目录维护'
        }
    },
    {
        path: 'chosen-drug-manage/list/detail',
        component: Detail,
        meta: {
            title: '关联中选产品'
        }
    }
];