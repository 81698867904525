/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-11-15 14:31:27
 * @Last Modified by: yanshuai
 * @Last Modified time: 2020-10-14 14:04:09
 * @Description: 4+7项目管理模块路由
 */

import View from '@/pages/mgr/procurement-project/view';

/** 药品 */
// 采购进度与结算分析
import PurchaseAnalysis from './purchase-analysis';
// 数据分析规则设置
import RuleSetting from './rule-setting';

/** 耗材 */
// 采购进度与结算分析
import ComPurchaseAnalysis from './com-purchase-analysis';
// 数据分析规则设置
import ComRuleSetting from './com-rule-setting';

export default [
    {
        path: 'procurement-project',
        component: View,
        meta: {
            title: '国家带量采购专题分析'
        },
        children: [
            ...PurchaseAnalysis,
            ...RuleSetting,
            ...ComPurchaseAnalysis,
            ...ComRuleSetting
        ]
    }
];