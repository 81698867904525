/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-12-11
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-07-22 16:09:57
 * @Description: 耗材目录管理路由
 */

const ConsumableList = () => import(/* webpackChunkName: "ConsumableList" */ '@/pages/tender/catalog-manage/catalog/consumable/list');
const ConsumableCatalog = () => import(/* webpackChunkName: "ConsumableCatalog" */ '@/pages/tender/catalog-manage/catalog/consumable/catalog');

export default [
    {
        path: 'catalog/consumable/list',
        component: ConsumableList,
        meta: {
            title: '目录管理'
        }
    },
    {
        path: 'catalog/consumable/list/catalog',
        component: ConsumableCatalog,
        meta: {
            title: '选择招标目录'
        }
    }
];
