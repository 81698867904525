/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-10-31 15:54:46
 * @Last Modified by: maozg
 * @Last Modified time: 2020-03-03 18:01:48
 * @Description: 采购计划管理模块
 */
import Drug from './drug/index';
import Consumable from './consumable/index';

export default [
    ...Drug,
    ...Consumable
];