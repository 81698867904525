import {
    CALogin,
    getUserInfo,
    queryMenu,
    userLoginout,
    getProjectMenu,
    getUpmsUserInfo,
    queryUpmsMenu
} from '@/api';
import {
    SET_USERINFO,
    GET_APPLY_MENU,
    SAVE_USER_MODULE_NUM,
    CLEAR_PAGE_TAGS
} from './mutation-type';

// 登录
const loginWithCA = ({ commit }, params) => (
    new Promise((resolve, reject) => {
        CALogin(params).then(res => {
            /* eslint no-param-reassign: 'off' */
            if (res.code !== '0') {
                reject(res.message);
            }
            if (res && res.code === '0') {
                commit(SET_USERINFO, res.data); // 设置用户信息
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);

// 获取用户信息
const fetchUserInfo = ({ commit }) => (
    new Promise((resolve, reject) => {
        getUserInfo().then(res => {
            if (res) {
                const { lv } = res.data;
                localStorage.setItem('lv', lv); 
                window.sessionStorage.setItem('admDvs', res.data.admDvs);
                commit(SET_USERINFO, res.data);
            } else {
                localStorage.setItem('lv', ''); 
                window.sessionStorage.setItem('admDvs', '');
                commit(SET_USERINFO, {});
            }
            resolve(res);
        }).catch(e => reject(e));
    })
);

// 获取upms用户信息
const fetchUpmsUserInfo = ({ commit }) => (
    new Promise((resolve, reject) => {
        getUpmsUserInfo().then(res => {
            if (res) {
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                commit(SET_USERINFO, res.data);
            } else {
                commit(SET_USERINFO, {});
            }
            resolve(res);
        }).catch(e => reject(e));
    })
);

// 退出
const fetchLoginOut = ({ commit }) => (
    new Promise((resolve, reject) => {
        userLoginout().then(res => {
            commit(SET_USERINFO, {});
            commit(CLEAR_PAGE_TAGS);
            localStorage.clear(); // 清除token
            resolve(res);
        }).catch(e => {
            commit(SET_USERINFO, {});
            commit(CLEAR_PAGE_TAGS);
            localStorage.clear(); // 清除token
            reject(e);
        });
    })
);

// 获取项目外菜单
const fetchApplyMenu = ({ commit }) => (
    new Promise((resolve, reject) => {
        queryMenu().then(res => {
            if (res.code !== 0) {
                reject(res.message);
            }
            if (res && res.code === 0) {
                commit(GET_APPLY_MENU, res.data);
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);

// 获取upms菜单
const fetchUpmsMenu = ({ commit }) => (
    new Promise((resolve, reject) => {
        queryUpmsMenu().then(res => {
            if (res.code !== 0) {
                reject(res.message);
            }
            if (res && res.code === 0) {
                console.log('1111', res.data);
                // console.log("new::::",JSON.stringify(res.data))
                commit(GET_APPLY_MENU, res.data);
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);

// 获取项目内菜单
const fetchProjectMenu = ({ commit }, params) => (
    new Promise((resolve, reject) => {
        getProjectMenu(params).then(res => {
            if (res.code !== 0) {
                reject(res.message);
            }
            if (res && res.code === 0) {
                commit(GET_APPLY_MENU, res.data);
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);

const saveUserModuleNum = ({ commit }, params) => {
    commit(SAVE_USER_MODULE_NUM, params);
};

// 清空项目
const clearPageTags = ({ commit }) => (
    commit(CLEAR_PAGE_TAGS)
);

export default {
    loginWithCA,
    fetchUserInfo,
    fetchApplyMenu,
    fetchProjectMenu,
    fetchLoginOut,
    fetchUpmsUserInfo,
    saveUserModuleNum,
    clearPageTags,
    fetchUpmsMenu
};
