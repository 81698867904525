/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 16:54:56
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-10-23 11:31:56
 * @Description: 项目路由数据
 * @Route: Route
 */

export default [
    'component-index', // 集成一包首页组件
    'base-manage', // 基础设置
    'purview-manage', // 权限设置
    'user-manage', // 用户设置
    // 'national-project/report', // 报量数据审核
    'outside-item', // 项目外首页
    'my-product', // 我的产品库
    'qualify-manage', // 资质库审核
    'my-business-info', // 我的企业信息
    'bdc', // 基础数据
    'purview', // 权限
    'mgr', // 监管
    'report-manage', // wedata
    'trade', // 交易结算
    'express-manage', // 配送管理
    'bidding-mode', // 招标模式管理
    'quantity-manage', // 国家报量项目管理
    'national-project/execution', // 国家项目执行管理
    'dynamic-adjust', // 动态调整
    'sunshine-hanging'// 阳光挂网
];
