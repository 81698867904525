/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2020-07-01 17:15:46
 * @Last Modified by: caoyp
 * @Last Modified time: 2020-12-07 19:32:41
 * @Description: 监管大屏
 */

const Home = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/visual');

export default [
    {
        path: 'visual/home',
        component: Home,
        meta: {
            title: '监管大屏'
        }
    }
];