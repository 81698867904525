import View from '@/components/view';

// 耗材-结算-结算支付
// 医疗机构
const medicalPaymentManagement = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/trade/settlement-payment/consumable/medical-payment-management');// 支付管理
const medicalPaymentLog = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/trade/settlement-payment/consumable/payment-log');
const medicalParticulars = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/trade/settlement-payment/consumable/particulars');
const createSettle = () => import(/* webpackChunkName: "createSettle" */ '@/pages/trade/settlement-payment/consumable/create-settle');
const createPay = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/create-pay');
const detailRead = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/detail-read');
const settleLog = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/settle-log');
const payLog = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/pay-log');
const settleDetail = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/settle-detail');
const PayDetail = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/pay-detail');
const detailReadDetail = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/detail-read');

// 上级结算支付-医疗机构
const superiorMedCreateSettle = () => import(/* webpackChunkName: "superiorMedCreateSettle" */ '@/pages/trade/settlement-payment/superior/consumable/med-create-settle');
const superiorMedCreatePay = () => import(/* webpackChunkName: "superiorMedCreatePay" */ '@/pages/trade/settlement-payment/superior/consumable/med-create-pay');
const superiorMedPayManagement = () => import(/* webpackChunkName: "superiorMedPayManagement" */ '@/pages/trade/settlement-payment/superior/consumable/med-payment-management');

// 管理机构
const supervisePaymentManagement = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/trade/settlement-payment/consumable/supervise-payment-management');
const particulars = () => import(/* webpackChunkName: "particulars" */ '@/pages/trade/settlement-payment/consumable/supervise-particulars');
const supervisePaymentLog = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/trade/settlement-payment/consumable/payment-log');
const superviseCreateSettle = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/trade/settlement-payment/consumable/supervise-create-settle');
const superviseCreatePay = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/trade/settlement-payment/consumable/supervise-create-pay');
const superviseDetailRead = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/supervise-detail-read');
const superviseSettleDetail = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/supervise-settle-detail');
const superviseSettleLog = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/settle-log');
const supervisePayDetail = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/supervise-pay-detail');
const supervisePaySettle = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/supervise-pay-settle');
const supervisePayLog = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/pay-log');
const readLog = () => import(/* webpackChunkName: "createPay" */ '@/pages/trade/settlement-payment/consumable/read-log');

// 上级结算支付-管理单位
const superiorAdmCreateSettle = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/trade/settlement-payment/superior/consumable/adm-create-settle');
const superiorAdmCreatePay = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/trade/settlement-payment/superior/consumable/adm-create-pay');
const superiorAdmPayManagement = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/trade/settlement-payment/superior/consumable/adm-payment-management');

const routes = [
    {
        path: 'settlement-payment/medical/consumable',
        component: View,
        meta: {
            title: '结算支付(自付)'
        },
        children: [
            {
                path: 'create-settle/list',
                component: createSettle,
                meta: {
                    title: '生成结算单'
                }
            },
            {
                path: 'create-settle/list/log',
                component: settleLog,
                meta: {
                    title: '任务日志',
                    display: 'none'
                }
            },
            {
                path: 'create-settle/list/read',
                component: detailRead,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'create-settle/list/detail',
                component: settleDetail,
                meta: {
                    title: '结算单明细',
                    display: 'none'
                }
            },
            {
                path: 'create-settle/list/detail/detail',
                component: detailReadDetail,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'create-pay/list',
                component: createPay,
                meta: {
                    title: '生成支付单'
                }
            },
            {
                path: 'create-pay/list/detail',
                component: PayDetail,
                meta: {
                    title: '结算单明细',
                    display: 'none'
                }
            },
            {
                path: 'create-pay/list/pay-detail',
                component: PayDetail,
                meta: {
                    title: '支付单明细',
                    display: 'none'
                }
            },
            {
                path: 'create-pay/list/log',
                component: payLog,
                meta: {
                    title: '任务日志',
                    display: 'none'
                }
            },
            {
                path: 'settlement-payment/list',
                component: medicalPaymentManagement,
                meta: {
                    title: '支付管理'
                }
            },
            {
                path: 'settlement-payment/list/log',
                component: medicalPaymentLog,
                meta: {
                    title: '任务日志',
                    display: 'none'
                }
            },
            {
                path: 'settlement-payment/list/read-log',
                component: readLog,
                meta: {
                    title: '任务日志',
                    display: 'none'
                }
            },
            {
                path: 'settlement-payment/list/particulars',
                component: medicalParticulars,
                meta: {
                    title: '支付单明细',
                    display: 'none'
                }
            }
        ]
    },
    {
        path: 'settlement-payment/supervise/consumable',
        component: View,
        meta: {
            title: '结算支付'
        },
        children: [
            {
                path: 'create-settle/list',
                component: superviseCreateSettle,
                meta: {
                    title: '生成结算单'
                }
            },
            {
                path: 'create-settle/list/read',
                component: superviseDetailRead,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'create-settle/list/log',
                component: superviseSettleLog,
                meta: {
                    title: '任务日志',
                    display: 'none'
                }
            },
            {
                path: 'create-settle/list/detail',
                component: superviseSettleDetail,
                meta: {
                    title: '结算单明细',
                    display: 'none'
                }
            },
            {
                path: 'create-pay/list',
                component: superviseCreatePay,
                meta: {
                    title: '生成支付单'
                }
            },
            {
                path: 'create-pay/list/detail',
                component: supervisePaySettle,
                meta: {
                    title: '结算单明细',
                    display: 'none'
                }
            },
            {
                path: 'create-pay/list/pay-detail',
                component: supervisePayDetail,
                meta: {
                    title: '支付单明细',
                    display: 'none'
                }
            },
            {
                path: 'create-pay/list/log',
                component: supervisePayLog,
                meta: {
                    title: '任务日志',
                    display: 'none'
                }
            },
            {
                path: 'settlement-payment/list',
                component: supervisePaymentManagement,
                meta: {
                    title: '支付管理'
                }
            },
            {
                path: 'settlement-payment/list/particulars',
                component: particulars,
                meta: {
                    title: '支付单明细',
                    display: 'none'
                }
            },            
            {
                path: 'settlement-payment/list/read-log',
                component: readLog,
                meta: {
                    title: '任务日志',
                    display: 'none'
                }
            },
            {
                path: 'settlement-payment/list/log',
                component: supervisePaymentLog,
                meta: {
                    title: '任务日志',
                    display: 'none'
                }
            }
        ]
    },
    {
        path: 'settlement-payment/consumable/superior/medical',
        component: View,
        meta: {
            title: '结算支付（上级）'
        },
        children: [
            {
                path: 'create-settle/list',
                component: superiorMedCreateSettle,
                meta: {
                    title: '生成结算单'
                }
            },
            {
                path: 'create-pay/list',
                component: superiorMedCreatePay,
                meta: {
                    title: '生成支付单'
                }
            },
            {
                path: 'pay-management/list',
                component: superiorMedPayManagement,
                meta: {
                    title: '支付管理'
                }
            }
        ]
    },
    {
        path: 'settlement-payment/consumable/superior/adm',
        component: View,
        meta: {
            title: '结算支付（上级）'
        },
        children: [
            {
                path: 'create-settle/list',
                component: superiorAdmCreateSettle,
                meta: {
                    title: '生成结算单'
                }
            },
            {
                path: 'create-pay/list',
                component: superiorAdmCreatePay,
                meta: {
                    title: '生成支付单'
                }
            },
            {
                path: 'pay-management/list',
                component: superiorAdmPayManagement,
                meta: {
                    title: '支付管理'
                }
            }
        ]
    }
];

export default routes;