/*
 * @Author: lina
 * @Email: lina5@guahao.com
 * @Date: 2020-03-03 10:28:43
 * @Last Modified by: caoyp
 * @Last Modified time: 2020-07-29 10:41:47
 * @Description: 采购数据统计
 * @Route: Route
 */

const PurTotal = () => import(/* webpackChunkName: "purchase-list" */ '@/pages/tender/quantity-statistics/drug/data-list');
const DrugFill = () => import(/* webpackChunkName: "quantity-fill" */ '@/pages/trade/quantity-manage/drug/fill');

export default [
    {
        path: 'drug/list',
        component: PurTotal,
        meta: {
            title: '采购数据汇总',
            flag: 'quantity'
        }
    },
    {
        path: 'drug/list/fill',
        component: DrugFill,
        meta: {
            title: '采购填报',
            flag: 'quantity'
        }
    }
];