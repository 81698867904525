/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-08-22 17:13:08
 * @Last Modified by: housy
 * @Last Modified time: 2019-09-03 16:31:58
 * @Description: 业务监管模块路由
 * @Route: Route
 */
import View from '@/components/view';
import MonitorWarning from './monitor-warning';

const BusinessHome = () => import(/* webpackChunkName: "business-home" */ '@/pages/mgr/business/home');
const BusinessNavigator = () => import(/* webpackChunkName: "business-navigator" */ '@/pages/mgr/business/navigator');

export default [
    {
        path: 'business',
        component: View,
        meta: {
            title: '业务监管'
        },
        children: [
            {
                path: 'home',
                component: BusinessHome,
                meta: {
                    title: '药品和医用耗材综合监管'
                }
            },
            {
                path: 'navigator',
                component: BusinessNavigator,
                meta: {
                    title: '全国监测预警集合页',
                    display: 'none'
                }
            },
            ...MonitorWarning
        ]
    }
];