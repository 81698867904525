/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2020/1/10
 * @Last Modified by: caoyp
 * @Last Modified time: 2020-09-11 17:54:07
 * @Description: 国家项目报量管理路由
 */

import View from '@/components/view';

const List = () => import(/* webpackChunkName: "List" */ '@/pages/tender/national-project-report-management/list');
// 药品
const DrugCheckout = () => import(/* webpackChunkName: "DrugCheckout" */ '@/pages/tender/national-project-report-management/drug/checkout');
// const DrugAuditData = () => import(/* webpackChunkName: "DrugAuditData" */ '@/pages/tender/national-project-report-management/drug/data-audit');
const DrugAuditLog = () => import(/* webpackChunkName: "DrugAuditLog" */ '@/pages/tender/national-project-report-management/drug/audit-log');
// const City = () => import(/* webpackChunkName: "City" */ '@/pages/tender/national-project-report-management/city-audit');
const Province = () => import(/* webpackChunkName: "Province" */ '@/pages/tender/national-project-report-management/province-audit');

const DrugReport = () => import(/* webpackChunkName: "drug-report" */ '@/pages/tender/national-project-report-management/drug/report');
const FillDrug = () => import(/* webpackChunkName: "fill-drug" */ '@/pages/tender/national-project-report-management/drug/fill-write');
const FillReportDrug = () => import(/* webpackChunkName: "fill-report-drug" */ '@/pages/tender/national-project-report-management/drug/fill-report');
// 耗材
const ConsumableCheckout = () => import(/* webpackChunkName: "ConsumableCheckout" */ '@/pages/tender/national-project-report-management/consumable/checkout');

const ConsumableReport = () => import(/* webpackChunkName: "consumable-report" */ '@/pages/tender/national-project-report-management/consumable/report');
const FillConsumable = () => import(/* webpackChunkName: "fill-consumable" */ '@/pages/tender/national-project-report-management/consumable/fill-write');
const FillReportConsumable = () => import(/* webpackChunkName: "fill-report-consumable" */ '@/pages/tender/national-project-report-management/consumable/fill-report');


export default [
    {
        path: 'national-project/report',
        component: View,
        meta: {
            title: '国家项目报量管理'
        },
        children: [
            {
                path: 'province',
                component: Province,
                meta: {
                    title: '采购数据审核',
                    flag: 'quantity'
                }
            },
            {
                path: 'province-audit',
                component: Province,
                meta: {
                    title: '采购数据审核',
                    flag: 'quantity'
                }
            },
            {
                path: 'list',
                component: List,
                meta: {
                    title: '国家项目报量管理',
                    flag: 'quantity'
                }
            },
            // 药品
            {
                path: 'drug/list/checkout',
                component: DrugCheckout,
                meta: {
                    title: '查看',
                    flag: 'quantity'
                }
            },
            {
                path: 'drug/audit/log',
                component: DrugAuditLog,
                meta: {
                    title: '审核日志',
                    flag: 'quantity'
                }
            },
            {
                path: 'drug/list/report',
                component: DrugReport,
                meta: {
                    title: '报量',
                    flag: 'quantity'
                }
            },
            {
                path: 'drug/list/report/fill-write',
                component: FillDrug,
                meta: {
                    title: '采购量填写',
                    flag: 'quantity'
                }
            },
            {
                path: 'drug/list/report/fill-write/report',
                component: FillReportDrug,
                meta: {
                    title: '填报',
                    flag: 'quantity'
                }
            },
            // 耗材
            {
                path: 'consumable/list/checkout',
                component: ConsumableCheckout,
                meta: {
                    title: '查看',
                    flag: 'quantity'
                }
            },
            {
                path: 'consumable/list/report',
                component: ConsumableReport,
                meta: {
                    title: '报量',
                    flag: 'quantity'
                }
            },
            {
                path: 'consumable/list/report/fill-write',
                component: FillConsumable,
                meta: {
                    title: '采购量填写',
                    flag: 'quantity'
                }
            },
            {
                path: 'consumable/list/report/fill-write/report',
                component: FillReportConsumable,
                meta: {
                    title: '填报',
                    flag: 'quantity'
                }
            }
        ]
    }
];
