<template>
    <div class="warp">
        <header>
            <div
                v-if="left"
                class="left">
                <a
                    :href="left.href"
                    target="other">
                    {{ left.title }}
                    <i
                        v-if="left.href"
                        class="link-icon" />
                </a>
            </div>
            <div class="middle">
                <div class="lside" />
                <div class="content cursor">
                    {{ currentTitle }}
                    <i
                        v-if="options.length"
                        class="content-icon" />
                    <ul
                        v-if="options.length"
                        class="content-list">
                        <li
                            v-for="item in options"
                            :key="item.index"
                            @click="()=>setValue(item)">
                            {{ item.item_title }}
                        </li>
                    </ul>
                </div>
                <div class="lside rside" />
            </div>

            <div
                v-if="right"
                class="right">
                <a
                    v-if="backUrl"
                    class="cursor"
                    @click="$router.push(backUrl)">返回</a>
                <a
                    v-else
                    class="cursor"
                    @click="$router.go(-1)">返回</a>
            </div>
        </header>
    </div>
</template>

<script>
export default {
    name: 'VwHeader',
    props: {
        options: {
            type: Array,
            default: () => []
        },
        // 大标题
        title: {
            type: String,
            required: false,
            default: ''
        },
        // 左侧内容和链接不限制传值 left:'';
        /* eslint-disable */
        left: {
            title: {
                type: String,
                required: false,
                default: '4+7'
            },
            href: {
                ype: String
            }
        },
        // 右侧返回按钮不显示传值 right:false;
        right: {
            type: Boolean,
            required: false,
            default: true
        },
        backUrl: {
            type: [String, Object],
            required: false,
            default: ''
        }
    },
    data() {
        return {
            currentTitle: this.title || (this.options[0] && this.options[0].label)
        };
    },
    watch: {
        title(newValue) {
            this.currentTitle = newValue;
        }
    },
    methods: {
        setValue(obj) {
            if (obj.label !== this.currentTitle) {
                this.currentTitle = obj.item_name;
                this.$emit('change', obj.item_code);
            }
        }
    }
};
</script>
<style lang='less' scoped>
.warp {
    position: relative;
    padding: 0 25px;
    z-index: 4;
}
header {
    width: 100%;
    height: 77px;
    // background: url("~@/assets/images/headerBG.png") no-repeat center / 100% 100%;
    position: relative;
    display: flex;
    text-align: center;
    color: @primary-green;
}
.middle {
    flex: 1;
    font-size: 27px;
    font-weight: 700;
    display: flex;
    height: 77px;
    justify-content: center;

    .lside {
        width: 334px;
        height: inherit;
        background: url('~@/images/visual/lside.png') no-repeat center / 100% 100%;
    }
    .rside {
        background-image: url('~@/images/visual/rside.png');
    }
    .content {
        position: relative;
        padding-top: 30px;
        min-width: 200px;
        max-width: 660px;
        padding-left: 36px;
        padding-right: 36px;
        background: url('~@/images/visual/content_block.png') repeat-x top center / auto 100%;
        position: relative;
        font-weight: 700;
        &::after {
            position: absolute;
            width: 27px;
            height: 14px;
            content: '';
            background: url('~@/images/visual/bside.png') no-repeat center / 100% 100%;
            bottom: -7px;
            right: 50%;
            margin-right: -13.5px;
        }
        .content-icon {
            position: absolute;
            right: 0;
            top: 35px;
            display: block;
            height: 26px;
            width: 26px;
            background-repeat:no-repeat ;
            background-image: url('~@/images/visual/dropdown.png');
        }
        .content-list {
            display: none;
            position: absolute;
            top:100%;
            left: -157px;
            min-width: 100%;
            background-color:#074d70 ;
            font-size: 20px;
            li {
                padding: 10px 0;
                &:hover {
                    background: #111d2c;
                }
            }
        }
        &:hover .content-list {
            display: block;
        }
    }
}
.right,
.left {
    width: 320px;
    position: absolute;
}
.left {
    height: 55px;
    color: @primary-green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    left: 0;
    top: 20px;
    &::before {
        left: 0;
        content: '';
        position: absolute;
        top: 20px;
        display: block;
        width: 0;
        height: 0;
        border-width: 7px 0px 7px 7px;
        border-style: solid;
        border-color: transparent transparent transparent @border-5;
    }
    &::after {
        right: 0;
        content: '';
        top: 20px;
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-width: 7px 7px 7px 0px;
        border-style: solid;
        border-color: transparent @border-5 transparent transparent;
        display: flex;
    }
    a {
        background-repeat: no-repeat;
        background-position: right 7px;
        transition: all 0.3s;
        font-size: 22px;
        color: inherit;
        font-weight: 700;
    }
}
.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    right: 0;
    top: 28px;
    a {
        box-sizing: border-box;
        width: 88px;
        height: 40px;
        display: inline-block;
        font-size: 20px;
        line-height: 40px;
        text-align: right;
        padding-right: 17px;
        background: url('~@/images/visual/go.png') no-repeat center / 100% 100%;
        color: inherit;
    }
}
.link-icon {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background: url('~@/images/visual/link_small.png') no-repeat center / cover;
}
</style>


