/*
 * @Author: panzw2
 * @Email: panzw2@guahao.com
 * @Date: 2020-12-14 19:01:37
 * @Last Modified by: panzw2
 * @Last Modified time: 2020-12-29 10:25:50
 * @Description: 综合监管/机构信息查询
 */

const purchasing = () => import(/* webpackChunkName: "drug-analyze" */ '@/pages/mgr/monitoring-warning/purchasing-abnormal');
const purchasingdetail = () => import(/* webpackChunkName: "drug-ranking" */ '@/pages/mgr/monitoring-warning/purchasing-abnormal-detail');
const supply = () => import(/* webpackChunkName: "drug-ranking" */ '@/pages/mgr/monitoring-warning/supply-of-abnormal');
const supplydetail = () => import(/* webpackChunkName: "drug-ranking" */ '@/pages/mgr/monitoring-warning/supply-of-abnormal-detail');
const setting = () => import(/* webpackChunkName: "drug-ranking" */ '@/pages/mgr/monitoring-warning/setting');
const index = () => import(/* webpackChunkName: "drug-ranking" */ '@/pages/mgr/monitoring-warning/index');
export default [
    {
        path: 'monitoring-warning/purchasing',
        component: purchasing,
        meta: {
            title: '采购异常监测预警'
        }
    },
    {
        path: 'monitoring-warning/purchasingdetail',
        component: purchasingdetail,
        meta: {
            title: '采购异常监测预警详情'
        }
    },
    {
        path: 'monitoring-warning/supply',
        component: supply,
        meta: {
            title: '供应异常监测预警'
        }
    },
    {
        path: 'monitoring-warning/supplydetail',
        component: supplydetail,
        meta: {
            title: '供应异常监测预警详情'
        }
    },
    {
        path: 'monitoring-warning/setting',
        component: setting,
        meta: {
            title: '监测预警参数设置'
        }
    },
    {
        path: 'monitoring-warning/index',
        component: index,
        meta: {
            title: '监测预警设置'
        }
    }
];