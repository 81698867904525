/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-11-15 14:42:15
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-08-21 15:49:27
 * @Description: 设置非中选品种模块路由
 */

const List = () => import(/* webpackChunkName: "chosen-drug-manage-list" */ '@/pages/mgr/procurement-project/rule-setting/chosen-drug-manage/list');
const SetProduct = () => import(/* webpackChunkName: "chosen-drug-manage-set-product" */ '@/pages/mgr/procurement-project/rule-setting/chosen-drug-manage/set-product');
const Detail = () => import(/* webpackChunkName: "chosen-drug-manage-detail" */ '@/pages/mgr/procurement-project/rule-setting/chosen-drug-manage/detail');

export default [
    {
        path: 'chosen-drug-manage/list',
        component: List,
        meta: {
            title: '设置中选品种'
        }
    },
    {
        path: 'chosen-drug-manage/list/set-product',
        component: SetProduct,
        meta: {
            title: '设置中选品种'
        }
    },
    {
        path: 'chosen-drug-manage/list/set-product/detail',
        component: Detail,
        meta: {
            title: '修改非中选产品信息'
        }
    }
];