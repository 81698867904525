
import View from '@/components/view';
import { routesDispath, routesMedical } from './settlement-manage';
import { routesMedicalPayment } from './settlement-payment';
import { routesDispathInfo, routesMedicalInfo } from './information-inquiry';

const AccountManagement = () => import(/* webpackChunkName: "dispatchingAccountManagement" */ '@/pages/trade/hospital-pay/account-management/drug');
const superviseAccountManagement = () => import(/* webpackChunkName: "superviseAccountManagement" */ '@/pages/trade/hospital-pay/account-management/components/supervise-account-management');
const RegulatorlList = () => import('@/pages/trade/hospital-pay/information-inquiry/drug/list-regulator');
const dispatchingTemplate = () => import(/* webpackChunkName: "commissionedToApply" */ '@/pages/trade/hospital-pay/account-management/components/template');

const routes = [
    {
        path: 'hospital-pay/account-management/dispatching/drug',
        component: View,
        meta: {
            title: '医院自付结算管理'
        },
        children: [
            ...routesDispath,
            {
                path: 'checking-manage',
                component: () => import('@/pages/trade/hospital-pay/checking-manage/drug/list-express'),
                meta: {
                    title: '对账管理'
                }
            },
            {
                path: 'checking-manage/checking',
                component: () => import('@/pages/trade/hospital-pay/checking-manage/drug/checking-express'),
                meta: {
                    title: '对账/继续对账',
                    display: 'none'
                }
            },
            {
                path: 'checking-manage/detail',
                component: () => import('@/pages/trade/hospital-pay/checking-manage/drug/detail-express'),
                meta: {
                    title: '对账详情',
                    display: 'none'
                }
            },
            {
                path: 'receivable-payment-manage',
                component: () => import('@/pages/trade/hospital-pay/pay-reconciliation/delivery'),
                meta: {
                    title: '账期管理'
                }
            },
            ...routesDispathInfo,
            // {
            //     path: 'information-inquiry',
            //     component: () => import('@/pages/trade/hospital-pay/information-inquiry/component/tracking-express'),
            //     meta: {
            //         title: '结算明细追踪'
            //     }
            // },
            {
                path: 'account-management/list',
                component: AccountManagement,
                meta: {
                    title: '结算账户管理'
                }
            },
            {
                path: 'account-management/list/add',
                component: () => import('@/pages/trade/hospital-pay/account-management/components/add'),
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/template', // 签订
                component: dispatchingTemplate,
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/loan-withdraw',
                component: () => import('@/pages/trade/hospital-pay/account-management/components/loan-withdraw'),
                meta: {
                    title: '贷款提现',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/review-flow',
                component: () => import('@/pages/trade/hospital-pay/account-management/components/review-flow'),
                meta: {
                    title: '查看流水',
                    display: 'none'
                }
            }
        ]
    },
    // 医疗机构
    {
        path: 'hospital-pay/account-management/medical/drug',
        component: View,
        meta: {
            title: '医院自付结算管理'
        },
        children: [
            ...routesMedical,
            {
                path: 'checking-manage',
                component: () => import('@/pages/trade/hospital-pay/checking-manage/drug/list-medical'),
                meta: {
                    title: '对账管理'
                }
            },
            {
                path: 'checking-manage/checking',
                component: () => import('@/pages/trade/hospital-pay/checking-manage/drug/checking-medical'),
                meta: {
                    title: '对账/继续对账',
                    display: 'none'
                }
            },
            {
                path: 'checking-manage/detail',
                component: () => import('@/pages/trade/hospital-pay/checking-manage/drug/detail-medical'),
                meta: {
                    title: '对账详情',
                    display: 'none'
                }
            },
            ...routesMedicalPayment,
            ...routesMedicalInfo,
            {
                path: 'receivable-payment-manage',
                component: () => import('@/pages/trade/hospital-pay/pay-reconciliation/medical'),
                meta: {
                    title: '账期管理'
                }
            },
            {
                path: 'account-management/list',
                component: AccountManagement,
                meta: {
                    title: '结算账户管理'
                }
            },
            {
                path: 'account-management/list/add',
                component: () => import('@/pages/trade/hospital-pay/account-management/components/add'),
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/template', // 签订
                component: dispatchingTemplate,
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/loan-withdraw',
                component: () => import('@/pages/trade/hospital-pay/account-management/components/loan-withdraw'),
                meta: {
                    title: '贷款提现',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/review-flow',
                component: () => import('@/pages/trade/hospital-pay/account-management/components/review-flow'),
                meta: {
                    title: '查看流水',
                    display: 'none'
                }
            },
            {
                path: 'check-receivable/settleDetail',
                component: () => import('@/pages/trade/hospital-pay/check-receivable/settle-detail'),
                meta: {
                    title: '查看结算单明细',
                    display: 'none'
                }
            },
            {
                path: 'check-receivable/payDetail',
                component: () => import('@/pages/trade/hospital-pay/check-receivable/pay-detail'),
                meta: {
                    title: '支付单明细',
                    display: 'none'
                }
            }
        ]
    },
    // 管理单位
    {
        path: 'hospital-pay/account-management/supervise/drug',
        component: View,
        meta: {
            title: '医院自付结算管理'
        },
        children: [
            {
                path: 'pay-reconciliation',
                component: () => import('@/pages/trade/hospital-pay/pay-reconciliation/supervise'),
                meta: {
                    title: '对账管理'
                }
            },
            // {
            //     path: 'flow-query',
            //     component: () => import('@/pages/trade/hospital-pay/flow-query/index'),
            //     meta: {
            //         title: '结算账户流水查询'
            //     }
            // },
            {
                path: 'regulator-list',
                component: RegulatorlList,
                meta: {
                    title: '医疗机构结算信息查询'
                }
            },
            {
                path: 'information-inquiry',
                component: () => import('@/pages/trade/hospital-pay/information-inquiry/component/tracking-regulator'),
                meta: {
                    title: '结算明细追踪'
                }
            },
            {
                path: 'account-management/list',
                component: superviseAccountManagement,
                meta: {
                    title: '结算账户审核'
                }
            }
            // {
            //     path: 'settlement-config',
            //     component: () => import('@/pages/trade/hospital-pay/settlement-config/index'),
            //     meta: {
            //         title: '结算账户配置'
            //     }
            // }
        ]
    }
];
export default routes;