/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-11-18 19:19:30
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-20 19:20:24
 * @Description: 综合监管/药品履约记录评分公示总路由
 */
const List = () => import(/* webpackChunkName: "performance-score-publicity-list" */ '@/pages/mgr/project-analysis/performance-score-publicity/list');
const Distribution = () => import(/* webpackChunkName: "performance-score-publicity-distribution" */ '@/pages/mgr/project-analysis/performance-score-publicity/distribution-detail');
const Production = () => import(/* webpackChunkName: "performance-score-publicity-production" */ '@/pages/mgr/project-analysis/performance-score-publicity/production-detail');

export default [
    {
        path: 'performance-score-publicity/list',
        component: List,
        meta: {
            title: '药品履约记录评分公示'
        }
    },
    {
        path: 'performance-score-publicity/list/distribution',
        component: Distribution,
        meta: {
            title: '配送企业评分',
            display: 'none'
        }
    },
    {
        path: 'performance-score-publicity/list/production',
        component: Production,
        meta: {
            title: '生产企业评分',
            display: 'none'
        }
    }
];