/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-11-04 18:13:25
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-12-11 16:01:26
 * @Description: 耗材招采统计分析总路由
 */
import View from '@/components/view';

const PurchaseDetail = () => import(/* webpackChunkName: "drug-purchase-detail" */ '@/pages/mgr/tender-stat-drug/purchase-detail');
const PurchaseConfluence = () => import(/* webpackChunkName: "drug-purchase-confluence" */ '@/pages/mgr/tender-stat-drug/purchase-confluence');
const TradAnalysisMedical = () => import(/* webpackChunkName: "drug-trad-analysis-medical" */ '@/pages/mgr/tender-stat-drug/trad-analysis-medical');
const TradAnalysisDelivery = () => import(/* webpackChunkName: "drug-trad-analysis-delivery" */ '@/pages/mgr/tender-stat-drug/trad-analysis-delivery');
const TradAnalysisProduct = () => import(/* webpackChunkName: "drug-trad-analysis-product" */ '@/pages/mgr/tender-stat-drug/trad-analysis-product');
const BillMessage = () => import(/* webpackChunkName: "drug-bill-message" */ '@/pages/mgr/tender-stat-drug/bill-message');
const SettmDetail = () => import(/* webpackChunkName: "drug-settm-detail" */ '@/pages/mgr/tender-stat-drug/settm-detail');
const ReceiveConflSupply = () => import(/* webpackChunkName: "drug-receive-confl-supply" */ '@/pages/mgr/tender-stat-drug/receive-confl-supply');
const PayConflMedical = () => import(/* webpackChunkName: "drug-pay-confl-medical" */ '@/pages/mgr/tender-stat-drug/pay-confl-medical');
const SettmStatSupply = () => import(/* webpackChunkName: "drug-settm-stat-supply" */ '@/pages/mgr/tender-stat-drug/settm-stat-supply');
const SettmStatMedical = () => import(/* webpackChunkName: "drug-settm-stat-medical" */ '@/pages/mgr/tender-stat-drug/settm-stat-medical');
const SettlementProfile = () => import(/* webpackChunkName: "drug-settlement-profile" */ '@/pages/mgr/tender-stat-drug/settlement-profile');
const SettlementMonitoring = () => import(/* webpackChunkName: "drug-settlement-monitoring" */ '@/pages/mgr/tender-stat-drug/settlement-monitoring/list');
const PayForAnother = () => import(/* webpackChunkName: "drug-pay-for-another" */ '@/pages/mgr/tender-stat-drug/pay-for-another/list');
const PayHospitalDetail = () => import(/* webpackChunkName: "drug-pay-for-another" */ '@/pages/mgr/tender-stat-drug/pay-for-another/hospital-detail');
const PayDeliveryDetail = () => import(/* webpackChunkName: "drug-pay-for-another" */ '@/pages/mgr/tender-stat-drug/pay-for-another/delivery-detail');
const HospitalSelfPay = () => import(/* webpackChunkName: "drug-hospital-self-pay" */ '@/pages/mgr/tender-stat-drug/hospital-self-pay/list');
const SelfHospitalDetail = () => import(/* webpackChunkName: "drug-hospital-self-pay" */ '@/pages/mgr/tender-stat-drug/hospital-self-pay/hospital-detail');
const SelfDeliveryDetail = () => import(/* webpackChunkName: "drug-hospital-self-pay" */ '@/pages/mgr/tender-stat-drug/hospital-self-pay/delivery-detail');

export default [
    {
        path: 'tender-stat-drug',
        component: View,
        meta: {
            title: '药品招采统计分析'
        },
        children: [
            {
                path: 'purchase-detail',
                component: PurchaseDetail,
                meta: {
                    title: '药品采购明细'
                }
            },
            {
                path: 'purchase-confluence',
                component: PurchaseConfluence,
                meta: {
                    title: '药品采购汇总'
                }
            },
            {
                path: 'trad-analysis-medical',
                component: TradAnalysisMedical,
                meta: {
                    title: '医疗机构药品交易分析'
                }
            },
            {
                path: 'trad-analysis-delivery',
                component: TradAnalysisDelivery,
                meta: {
                    title: '配送企业药品交易分析'
                }
            },
            {
                path: 'trad-analysis-product',
                component: TradAnalysisProduct,
                meta: {
                    title: '生产企业药品交易分析'
                }
            },
            {
                path: 'bill-message',
                component: BillMessage,
                meta: {
                    title: '药品票据信息查询'
                }
            },
            {
                path: 'settm-detail',
                component: SettmDetail,
                meta: {
                    title: '药品结算明细'
                }
            },
            {
                path: 'receive-confl-supply',
                component: ReceiveConflSupply,
                meta: {
                    title: '供应企业药品应收汇总'
                }
            },
            {
                path: 'pay-confl-medical',
                component: PayConflMedical,
                meta: {
                    title: '医疗结构药品应付汇总'
                }
            },
            {
                path: 'settm-stat-supply',
                component: SettmStatSupply,
                meta: {
                    title: '供应企业药品结算统计'
                }
            },
            {
                path: 'settm-stat-medical',
                component: SettmStatMedical,
                meta: {
                    title: '医疗机构药品结算统计'
                }
            },
            {
                path: 'settlement-profile',
                component: SettlementProfile,
                meta: {
                    title: '药品结算概况分析'
                }
            },
            {
                path: 'settlement-monitoring',
                component: SettlementMonitoring,
                meta: {
                    title: '药品综合结算监测'
                }
            },
            {
                path: 'pay-for-another',
                component: PayForAnother,
                meta: {
                    title: '医保代付药品结算监测'
                }
            },
            {
                path: 'pay-for-another/hospital-detail',
                component: PayHospitalDetail,
                meta: {
                    title: '医保局结算监测'
                }
            },
            {
                path: 'pay-for-another/delivery-detail',
                component: PayDeliveryDetail,
                meta: {
                    title: '配送企业结算监测'
                }
            },
            {
                path: 'hospital-self-pay',
                component: HospitalSelfPay,
                meta: {
                    title: '医院自付药品结算监测'
                }
            },
            {
                path: 'hospital-self-pay/hospital-detail',
                component: SelfHospitalDetail,
                meta: {
                    title: '医院药品结算监测'
                }
            },
            {
                path: 'hospital-self-pay/delivery-detail',
                component: SelfDeliveryDetail,
                meta: {
                    title: '配送企业结算监测'
                }
            }
        ]
    }
];