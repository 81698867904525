/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-11-15 14:42:15
 * @Last Modified by: panzw2
 * @Last Modified time: 2021-01-13 18:21:03
 * @Description: 机构约定量配置模块路由
 */

const List = () => import(/* webpackChunkName: "org-appoint-config-list" */ '@/pages/mgr/procurement-project/com-rule-setting/org-appoint-config/list');
const Detail = () => import(/* webpackChunkName: "org-appoint-config-list" */ '@/pages/mgr/procurement-project/com-rule-setting/org-appoint-config/detail');
export default [
    {
        path: 'org-appoint-config/list',
        component: List,
        meta: {
            title: '设置耗材约定量'
        }
    },
    {
        path: 'org-appoint-config/detail',
        component: Detail,
        meta: {
            title: '约定量配置'
        }
    }
    
];