/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-12-09
 * @Last Modified by: wangxiaoyu
 * @Last Modified time: 2019-12-09
 * @Description: 项目内药品-药企端页面路由
 */

const Business = () => import(/* webpackChunkName: "business" */ '@/pages/tender/signup-manage/enterprise/drug/sign-up/business');
const DrugHistory = () => import(/* webpackChunkName: "history" */ '@/pages/tender/signup-manage/enterprise/drug/sign-up/history');

export default [
    {
        path: 'enterprise/drug/sign-up',
        component: Business,
        meta: {
            title: '企业报名',
            display: 'none'
        }
    },
    {
        path: 'enterprise/drug/sign-up/history',
        component: DrugHistory,
        meta: {
            title: '查看历史详情',
            display: 'none'
        }
    }
];
