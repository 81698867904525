/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-10-31 15:54:46
 * @Last Modified by: maozg
 * @Last Modified time: 2019-11-27 14:38:30
 * @Description: 结算申请管理模块
 */
import Drug from './drug/index';
import Consumable from './consumable/index';

export default [
    ...Drug,
    ...Consumable
];