import View from '@/components/view';

// 目录管理-耗材-配送企业
const catalogDefendList = () => import(/* webpackChunkName: "catalogDefendList" */ '@/pages/trade/distribution-directory/consumable/catalog-defend-list');
const catalogDefendListRead = () => import(/* webpackChunkName: "catalogDefendListRead" */ '@/pages/trade/distribution-directory/consumable/catalog-defend-list-read');
const inventoryMaintenance = () => import(/* webpackChunkName: "inventoryMaintenance" */ '@/pages/trade/distribution-directory/consumable/inventory-maintenance');
const secondaryBargainingList = () => import(/* webpackChunkName: "secondaryBargainingList" */ '@/pages/trade/distribution-directory/consumable/secondary-bargaining-list');

const routes = [
    {
        path: 'distribution-directory/consumable',
        component: View,
        meta: {
            title: '目录管理'
        },
        children: [
            {
                path: 'distribution-directory/list',
                component: catalogDefendList,
                meta: {
                    title: '配送目录查询'
                }
            },
            {
                path: 'distribution-directory/list/read',
                component: catalogDefendListRead,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'inventory-maintenance',
                component: inventoryMaintenance,
                meta: {
                    title: '配送库存维护'
                }
            },
            {
                path: 'secondary-bargaining-list',
                component: secondaryBargainingList,
                meta: {
                    title: '二次议价管理'
                }
            }
            
        ]
    }
];

export default routes;