/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2020-12-14 17:01:02
 * @Last Modified by: yanshuai
 * @Last Modified time: 2020-12-22 15:44:18
 * @Description:  耗材招采统计分析
 */

const netDirectoryQuery = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/consumables-tatistical-analysis/net-directory-query');
const consumablesHospitalDirectory = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/consumables-tatistical-analysis/consumables-hospital-directory');
const consumablesTradingProfile = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/consumables-tatistical-analysis/consumables-trading-profile');
const recordPurchaseInquiry = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/consumables-tatistical-analysis/record-purchase-inquiry');
const settlementSupervision = () => import(/* webpackChunkName: "index" */ '@/pages/mgr/full-amount/consumables-tatistical-analysis/settlement-supervision');

export default [
    {
        path: 'full/consumables/net-directory-query',
        component: netDirectoryQuery,
        meta: {
            title: '耗材挂网目录查询'
        }
    },
    {
        path: 'full/consumables/consumables-hospital-directory',
        component: consumablesHospitalDirectory,
        meta: {
            title: '耗材院内目录查询'
        }
    },
    {
        path: 'full/consumables/consumables-trading-profile',
        component: consumablesTradingProfile,
        meta: {
            title: '耗材交易概况分析'
        }
    },
    {
        path: 'full/consumables/record-purchase-inquiry',
        component: recordPurchaseInquiry,
        meta: {
            title: '耗材备案采购查询'
        }
    },
    {
        path: 'full/consumables/settlement-supervision',
        component: settlementSupervision,
        meta: {
            title: '耗材结算概况分析'
        }
    }
];