/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2020-02-20 14:28:40
 * @Last Modified by: panzw2
 * @Last Modified time: 2021-02-08 15:06:17
 * @Description: 4+7药品采购分析
 * @Route: Route
 */
const List = () => import(/* webpackChunkName: "purchase-special-list" */ '@/pages/mgr/procurement-project/com-purchase-analysis/purchase-special/list');
const purchaseDetails = () => import(/* webpackChunkName: "purchase-details" */ '@/pages/mgr/procurement-project/com-purchase-analysis/purchase-special/purchase-details');


export default [
    {
        path: 'purchase-special/list',
        component: List,
        meta: {
            title: '带量采购总体监管'
        } 
    },
    {
        path: 'purchase/details',
        component: purchaseDetails,
        meta: {
            title: '采购进度明细查询'
        }
    }
    
];