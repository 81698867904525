/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-08-22 15:33:47
 * @Last Modified by: maozg
 * @Last Modified time: 2019-09-05 14:09:48
 * @Description: 业务监管/监测预警/收货率不达标预警
 */

const List = () => import(/* webpackChunkName: "take-delivery-unstandard-list" */ '@/pages/mgr/business/monitor-warning/take-delivery-unstandard/list');
const ListProvince = () => import(/* webpackChunkName: "take-delivery-unstandard-list" */ '@/pages/mgr/business/monitor-warning/take-delivery-unstandard/list-province');
const Detail = () => import(/* webpackChunkName: "take-delivery-unstandard-list" */ '@/pages/mgr/business/monitor-warning/take-delivery-unstandard/detail');

export default [
    {
        path: 'take-delivery-unstandard/list',
        component: List,
        meta: {
            title: '收货率不达标预警'
        }
    },
    {
        path: 'take-delivery-unstandard/list-province',
        component: ListProvince,
        meta: {
            title: '各省医疗机构收货率不达标预警',
            display: 'none'
        }
    },
    {
        path: 'take-delivery-unstandard/detail',
        component: Detail,
        meta: {
            title: '医疗机构收货率趋势详情',
            display: 'none'
        }
    }
];
