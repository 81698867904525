/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 谈判管理/谈判结果查询
 */
const NegotiateList = () => import(/* webpackChunkName: "negotiate-list" */ '@/pages/tender/negotiate-manage/result-query/negotiate-list');

export default [
    {
        path: 'result-query/regulator-org/list',
        component: NegotiateList,
        meta: {
            title: '谈判结果管理',
            display: 'none'
        }
    },
    {
        path: 'result-query/product-org/list',
        component: NegotiateList,
        meta: {
            title: '谈判结果查询',
            display: 'none'
        }
    }
];
